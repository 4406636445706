import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import Chip from "@mui/material/Chip";
import styles from "./JobEdit.module.scss";

const JobEdit = ({
  show,
  handleClose,
  callback,
  data,
  currentJobsIndex,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [institutionOptions, setInstitutionOptions] = useState([]);
  const [institution, setInstitution] = useState(undefined);
  const [rol, setRol] = useState("");
  const [rolDescription, setRolDescription] = useState("");
  const [fromDate, setfromDate] = useState(undefined);
  const [toDate, settoDate] = useState(undefined);
  const [institutionId, setInstitutionId] = useState(undefined);
  const [key, setKey] = useState(0);

  const [job, setJob] = useState(undefined);
  const [jobs, setJobs] = useState([]);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const handleChange = (event, value, reason, details) => {
    event.preventDefault();

    setInstitutionId(value.institution_id);
    setInstitution(value);
  };

  const findInstitution = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/institutions?page=1&pageSize=100`,
        prepareHeaders()
      )
      .then((res) => {
        setInstitutionOptions(res.status == 204 ? [] : res.data.content);
      })
      .catch((err) => {
        console.log(err);
        setInstitutionOptions([]);
      });
  };

  useEffect(() => {
    findInstitution();
  }, []);

  useEffect(() => {
    if (!add) {
      if (
        data &&
        data.person_institutions &&
        currentJobsIndex !== undefined &&
        data.person_institutions[currentJobsIndex] !== undefined
      ) {
        const currentJob = data.person_institutions[currentJobsIndex];
        setJob({
          institutionId: currentJob.person_institutionId,
          institution: currentJob.institution,
          rol: currentJob.rol,
          rolDescription: currentJob.rol_description,
          fromDate: currentJob.from_date,
          toDate: currentJob.to_date,
        });

        const selectedInstitution = institutionOptions.find(
          (inst) => inst.institution_id === currentJob.person_institutionId
        );
        setInstitution(selectedInstitution);
      }
    } else {
      const currentJob = data.person_institutions[currentJobsIndex];
      setJob({
        institutionId: "",
        institution: "",
        rol: "",
        rolDescription: "",
        fromDate: "",
        toDate: "",
      });
    }
  }, [data, currentJobsIndex, add]);

  const buildBody = () => {
    if (!add) {
      return {
        person_institutionId: job.institutionId,
        institution: job.institution,
        rol: job.rol,
        rol_description: job.rolDescription,
        from_date: job.fromDate,
        to_date: job.toDate,
      };
    } else {
      return {
        institution_id: institutionId,
        rol: job.rol,
        rol_description: job.rolDescription,
        from_date: job.fromDate,
        to_date: job.toDate,
      };
    }
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/person/${data.personId}/institution/${data.person_institutions[currentJobsIndex].person_institutionId}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
        handleClose();
      });
  };

  const handleInputChange = (field, value) => {
    setJob((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    if (!job.rol || job.institutionId) {
      toast.error("Por favor seleccione la institución e ingrese el rol.");
      return;
    }
    const URL = `${process.env.REACT_APP_API_URL}/person/${data.personId}/institution`;
    axios
      .post(URL, buildBody(), prepareHeaders())
      .then((res) => {
        toast.success("Institución registrada exitosamente", {
          position: "top-center",
        });
        handleClose();
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la institución ", {
          position: "top-center",
        });
        console.log(err);
      });
      handleClose();
  };

  return (data.person_institutions[currentJobsIndex] !== undefined &&
    job !== undefined) ||
    add == true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar Institución" : "Editar Institución"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Trabajo {currentJobsIndex + 1}</h6>
            <Row className="mb-4">
              <Col md={6}>
                {add ? (
                  <Autocomplete
                    //multiple
                    key={key}
                    id="tags-filled"
                    options={institutionOptions.map((option) => option)}
                    freeSolo
                    getOptionLabel={(option) => option.name}
                    renderOption={(props, option) => (
                      <li {...props} key={option.institution_id}>
                        {" "}
                        {option.name}
                      </li>
                    )}
                    onChange={handleChange}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label="Institución/ Lugar de trabajo"
                      />
                    )}
                  />
                ) : (
                  <Form.Floating>
                    <Form.Control
                      required
                      id="rol"
                      type="text"
                      value={job?.institution.name || " "}
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="rol">
                      Institución
                    </label>
                  </Form.Floating>
                )}
              </Col>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="rol"
                    type="text"
                    value={job?.rol || " "}
                    onChange={(e) => {
                      handleInputChange("rol", e.target.value);
                    }}
                    placeholder="Ingrese el rol"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="rol">
                    Rol
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="rol_description"
                    type="text"
                    value={job?.rolDescription || " "}
                    onChange={(e) => {
                      handleInputChange("rolDescription", e.target.value);
                    }}
                    placeholder="Ingrese la descripción del rol"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="rol_description">
                    Descripción
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="from_date"
                    required={false}
                    value={job?.fromDate || " "}
                    onChange={(e) => {
                      handleInputChange("fromDate", e.target.value);
                    }}
                    className={styles.input}
                    placeholder="Ingrese la fecha en que empezó"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <label htmlFor="from_date" className={styles.label}>
                    Fecha en la cuál ingresó
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="to_date"
                    required={false}
                    value={job?.toDate || " "}
                    onChange={(e) => {
                      handleInputChange("toDate", e.target.value);
                    }}
                    className={styles.input}
                    placeholder="Ingrese la fecha en que empezó"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                  />
                  <label htmlFor="to_date" className={styles.label}>
                    Fecha en la cuál terminó
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={add ? handleSave : patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>{add ? "Agregando..." : "Actualizando..."}</span>
              </>
            ) : (
              <span>{add ? "Agregar Trabajo" : "Guardar Cambios"}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default JobEdit;
