import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "../Personas.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../../Keycloak";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import ContactModal from "../PersonModals/ContactModal/ContactModal";

const Contactos = ({
  updateContacts,
  prevStep,
  nextStep,
  contacts,
  setContacts,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();
  ///esto es nuevo//////

  const { keycloak } = useKeycloak();

  const [contactNumber, setContactNumber] = useState(null);
  const [contactType, setContactType] = useState("EMAIL");
  const [especificType, setEspecificType] = useState("PERSONAL");
  const [verified, setVerified] = useState(true);
  const [otherEspecificType, setOtherEspecificType] = useState("");

  //const [contacts, setContacts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [showContact, setShowContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  useEffect(() => {}, []);

  const removeContact = (e, contact) => {
    e.preventDefault();
    const updatedContacts = contacts.filter((current) => current !== contact);

    setContacts(updatedContacts);

    updateContacts(updatedContacts);
  };

  const array = () => {
    if (!validateFields() || !validateContact()) {
      return;
    }
    const newContact = {
      contact_number: contactNumber,
      contact_type: contactType,
      especific_type:
        especificType === "OTHER" ? otherEspecificType : especificType,
      verified: verified,
    };
    const newContacts = [...contacts];
    newContacts.push(newContact);
    setContacts(newContacts);
    updateContacts(newContacts);
    cleanAll();
  };

  const cleanAll = () => {
    setContactNumber("");
    setContactType("EMAIL");
    setEspecificType("PERSONAL");
    setOtherEspecificType("");
    setVerified(true);
  };

  const validateFields = () => {
    if (!contactNumber || !contactType) {
      toast.error("Debe cargar un numero de contacto y seleccionar el tipo.");
      return false;
    }
    return true;
  };

  const validateContact = () => {
    if (
      contactType === "EMAIL" &&
      !/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(contactNumber)
    ) {
      toast.error("Por favor, ingrese un correo válido.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (contacts.length === 0) {
      console.log("No se han agregado contactos");
      return;
    }

    nextStep();
  };

  const skip = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <>
      <ContactModal
        data={selectedContact}
        show={showContact}
        handleClose={() => setShowContact(false)}
      />
      <Form onSubmit={handleSubmit}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Row className="mt-4 mb-4">
            <Col md={4}>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    name="contact_type"
                    className={styles.input}
                    as="select"
                    value={contactType}
                    onChange={(e) => {
                      setContactType(e.target.value);
                    }}
                  >
                    <option value="EMAIL">Email</option>
                    <option value="TELEPHONE">Teléfono</option>
                  </Form.Control>
                  <label htmlFor="contact_type" className={styles.label}>
                    Tipo de contacto
                  </label>
                </Form.Floating>
              </Form.Group>
            </Col>
          </Row>
          <Row className={`mt-2 mb-4 ${styles.flexRow}`}>
            <Col md={4}>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="contact_number"
                    value={contactNumber}
                    onChange={(e) => {
                      setContactNumber(e.target.value);
                    }}
                    placeholder={
                      contactType === "EMAIL"
                        ? "Ingrese el email"
                        : "Ingrese el número de teléfono"
                    }
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="contact_number">
                    {contactType === "EMAIL" ? "Email" : "Número de teléfono"}
                  </label>
                </Form.Floating>
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    name="especific_type"
                    className={styles.input}
                    as="select"
                    value={especificType}
                    onChange={(e) => setEspecificType(e.target.value)}
                    required
                  >
                    <option value="PERSONAL">Personal</option>
                    <option value="LABORAL">Laboral</option>
                    <option value="OTHER">Otro</option>
                  </Form.Control>
                  <label htmlFor="especific_type" className={styles.label}>
                    {contactType === "EMAIL"
                      ? "Tipo de email"
                      : "Tipo de teléfono"}
                  </label>
                </Form.Floating>
              </Form.Group>
            </Col>

            {especificType === "OTHER" && (
              <Col md={4}>
                <Form.Group as={Col} className={`${styles.flexCol}`}>
                  <Form.Floating className="mt-3">
                    <Form.Control
                      name="other_especific_type_description"
                      className={styles.input}
                      type="text"
                      placeholder="Ingrese la descripción"
                      value={otherEspecificType}
                      onChange={(e) => setOtherEspecificType(e.target.value)}
                      required
                    />
                    <label
                      htmlFor="other_especific_type_description"
                      className={styles.label}
                    >
                      Especifique
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Col>
            )}
          </Row>
          <Row className="mt-4 mb-4">
            <Col md={4}>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    name="verified"
                    className={styles.input}
                    as="select"
                    value={verified}
                    onChange={(e) => {
                      setVerified(e.target.value);
                    }}
                  >
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </Form.Control>
                  <label htmlFor="verified" className={styles.label}>
                    Verificado
                  </label>
                </Form.Floating>
              </Form.Group>
            </Col>
          </Row>
          {contacts && contacts.length > 0 ? (
            <Table className={styles.table} style={{ marginTop: "1rem" }}>
              <thead>
                <th>Tipo de contacto</th>
                <th>Contacto</th>
                <th>Especificación de contacto</th>
                <th>Verificado</th>
                <th>Acciones</th>
              </thead>
              <tbody>
                {contacts.map((contact) => (
                  <tr>
                    <td>
                      {contact.contact_type === "EMAIL" ? "Email" : "Teléfono"}
                    </td>
                    <td>{contact.contact_number}</td>
                    <td>
                      {contact.especific_type === "PERSONAL"
                        ? "Personal"
                        : contact.especific_type === "LABORAL"
                        ? "Laboral"
                        : contact.especific_type}
                    </td>
                    <td>{contact.verified === true ? "Si" : "No"}</td>
                    <td>
                      <AiOutlineFileSearch
                        onClick={(e) => {
                          e.preventDefault();

                          setSelectedContact(contact);
                          setShowContact(true);
                        }}
                        title="Ver"
                        className={styles.icon}
                      />
                      &nbsp;
                      <FiTrash
                        onClick={(e) => removeContact(e, contact)}
                        title="Descartar"
                        className={styles.icon}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button
              bsPrefix={styles.next}
              onClick={() => {
                array();
              }}
              className="mt-3"
            >
              GUARDAR CONTACTO
            </Button>
          </Col>

          <Row
            className="mt-4"
            style={{ display: "flex", paddingTop: ".7rem" }}
          >
            <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
              <Button bsPrefix={styles.next} onClick={prevStep}>
                ANTERIOR
              </Button>
            </Col>

            {contacts.length > 0 ? (
              <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button bsPrefix={styles.next} type="submit">
                  SIGUIENTE
                </Button>
              </Col>
            ) : (
              <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button bsPrefix={styles.next} onClick={skip}>
                  OMITIR
                </Button>
              </Col>
            )}
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default Contactos;
