import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Modal, Table, Badge } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import styles from "./Information.module.css";
import DocumentType from "../../../../constants/DocumentType";
import ComplainantModal from "../../../../components/modals/ComplainantModal";
import DefendantModal from "../../../../components/modals/DefendantModal";
import WitnessModal from "../../../../components/modals/WitnessModal";
import DatosGeneralesEdit from "../Edit/DatosGeneralesEdit/DatosGeneralesEdit";
import { GrFormView, GrFormTrash, GrFormEdit } from "react-icons/gr";
import { HiDownload } from "react-icons/hi";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import ComplainantAdd from "../../../causas/CausaContext/Add/ComplainantAdd";
import DefendantAdd from "../../../causas/CausaContext/Add/DefendantAdd/DefendantAdd";
import PlaceEdit from "../../../causas/CausaContext/Edit/PlaceEdit/PlaceEdit";
import StatementOfFactEdit from "../../../causas/CausaContext/Edit/StatementOfFactEdit/StatementOfFactEdit";
import WitnessAdd from "../../../causas/CausaContext/Add/WitnessAdd/WitnessAdd";
import ExpertReportAdd from "../../../causas/CausaContext/Add/ExpertReportAdd/ExpertReportAdd";
import AttachmentAdd from "../../../causas/CausaContext/Add/AttachmentAdd/AttachmentAdd";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import GenericDetail from "../../../causas/CausaContext/Information/GenericDetail/GenericDetail";
import DirectionEdit from "../Edit/DirectionEdit/DirectionEdit";
import ContactEdit from "../Edit/ContactEdit/ContactEdit";
import JobEdit from "../Edit/JobEdit/JobEdit";

const Information = ({ person, setPerson, callback }) => {
  const { keycloak } = useKeycloak();

  const navigate = useNavigate();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteContext, setDeleteContext] = useState("");

  const [isAddMode, setIsAddMode] = useState(false);
  const [isAddContactMode, setIsAddContactMode] = useState(false);
  const [isAddJobMode, setIsAddJobMode] = useState(false);
  const [datosGeneralesEdit, setDatosGeneralesEdit] = useState(false);
  const [directionEdit, setDirectionEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);
  const [jobEdit, setJobEdit] = useState(false);

  const [institution, setInstitution] = useState(null);
  const [loadingInstitution, setLoadingInstitution] = useState(false);

  const [currentAddressIndex, setCurrentAddressIndex] = useState(0);
  const [currentContactsIndex, setCurrentContactsIndex] = useState(0);
  const [currentJobsIndex, setCurrentJobsIndex] = useState(0);

  const handleNext = () => {
    if (currentAddressIndex < person.addresses.length - 1) {
      setCurrentAddressIndex(currentAddressIndex + 1);
    }
  };

  const handleNextContacts = () => {
    if (currentContactsIndex < person.contacts.length - 1) {
      setCurrentContactsIndex(currentContactsIndex + 1);
    }
  };

  const handleNextJobs = () => {
    if (currentJobsIndex < person.person_institutions.length - 1) {
      setCurrentJobsIndex(currentJobsIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentAddressIndex > 0) {
      setCurrentAddressIndex(currentAddressIndex - 1);
    }
  };

  const handlePreviousContacts = () => {
    if (currentContactsIndex > 0) {
      setCurrentContactsIndex(currentContactsIndex - 1);
    }
  };

  const handlePreviousJobs = () => {
    if (currentJobsIndex > 0) {
      setCurrentJobsIndex(currentJobsIndex - 1);
    }
  };

    const currentAddress =
      person.addresses[currentAddressIndex] == undefined
      ? {}
      : person.addresses[currentAddressIndex];

  const currentContact =
    person.contacts[currentContactsIndex] == undefined
      ? {}
      : person.contacts[currentContactsIndex];
  const currentJob =
    person.person_institutions[currentJobsIndex] == undefined
      ? {}
      : person.person_institutions[currentJobsIndex];

  const prepareHeaders = () => {
    return {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const deleteAddress = (e, id) => {
    e.preventDefault();
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/person/${person.personId}/address/${id}`,
          prepareHeaders()
        )
        .then((res) => {
          console.log(res);
          toast.success("Dirección eliminada con éxito");

          setPerson((prev) => ({
            ...prev,
            addresses: prev.addresses.filter(
              (address) => address.addressId !== id
            ),
          }));

          const auxAddressIndex =
            currentAddressIndex >= person.addresses.length - 1
              ? currentAddressIndex - 1
              : currentAddressIndex;
          if (auxAddressIndex >= 0) {
            setCurrentAddressIndex(auxAddressIndex);
          } else {
            setCurrentAddressIndex(0);
          }
          setConfirmDelete(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Ha ocurrido un error al eliminar la dirección");
          setConfirmDelete(false);
        });
    } catch (err) {
      console.log(err);
      toast.error("Ha ocurrido un error al eliminar la dirección");
      setConfirmDelete(false);
    }
  };

  const deleteContact = (e, id) => {
    e.preventDefault();
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/person/${person.personId}/contact/${id}`,
          prepareHeaders()
        )
        .then((res) => {
          console.log(res);
          toast.success("Contacto eliminado con éxito");

          setPerson((prev) => ({
            ...prev,
            contacts: prev.contacts.filter(
              (contact) => contact.contactId !== id
            ),
          }));

          setCurrentContactsIndex((prevIndex) =>
            prevIndex >= person.contacts.length - 1 ? prevIndex - 1 : prevIndex
          );

          const auxContactIndex =
            currentContactsIndex >= person.contacts.length - 1
              ? currentContactsIndex - 1
              : currentContactsIndex;
          if (auxContactIndex >= 0) {
            setCurrentContactsIndex(auxContactIndex);
          } else {
            setCurrentContactsIndex(0);
          }

          setConfirmDelete(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Ha ocurrido un error al eliminar el contacto");
          setConfirmDelete(false);
        });
    } catch (err) {
      console.log(err);
      toast.error("Ha ocurrido un error al eliminar el contacto");
      setConfirmDelete(false);
    }
  };

  const deletePersonInstitution = (e, id) => {
    e.preventDefault();
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/person/${person.personId}/institution/${id}`,
          prepareHeaders()
        )
        .then((res) => {
          console.log(res);
          toast.success("Institución laboral eliminada con éxito");

          setPerson((prev) => ({
            ...prev,
            person_institutions: prev.person_institutions.filter(
              (job) => job.person_institutionId !== id
            ),
          }));

          setCurrentJobsIndex((prevIndex) =>
            prevIndex >= person.person_institutions.length - 1
              ? prevIndex - 1
              : prevIndex
          );

          const auxJobIndex =
            currentJobsIndex >= person.person_institutions.length - 1
              ? currentJobsIndex - 1
              : currentJobsIndex;
          if (auxJobIndex >= 0) {
            setCurrentJobsIndex(auxJobIndex);
          } else {
            setCurrentJobsIndex(0);
          }
          setConfirmDelete(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            "Ha ocurrido un error al eliminar la institución laboral"
          );
          setConfirmDelete(false);
        });
    } catch (err) {
      console.log(err);
      toast.error("Ha ocurrido un error al eliminar la institución laboral");
      setConfirmDelete(false);
    }
  };

  useEffect(() => {
    if (currentJob && currentJob.person_institutionId) {
      setLoadingInstitution(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/institutions/${currentJob.person_institutionId}`,
          prepareHeaders()
        )
        .then((response) => {
          setInstitution(response.data);
          setLoadingInstitution(false);
        })
        .catch((error) => {
          console.error("Error fetching institution:", error);
          setLoadingInstitution(false);
        });
    }
  }, [currentJob]);

  return (
    <>
      <Toaster />
      <DatosGeneralesEdit
        data={person}
        show={datosGeneralesEdit}
        handleClose={() => setDatosGeneralesEdit(false)}
        callback={() => {
          setDatosGeneralesEdit(false);
          callback();
        }}
      />
      <DirectionEdit
        data={person}
        show={directionEdit}
        add={isAddMode}
        callback={() => {
          callback();
        }}
        handleClose={() => {
          callback();
          setDirectionEdit(false);
          setIsAddMode(false);
        }}
        currentAddressIndex={currentAddressIndex}
        currentAddress={currentAddress}
      />
      <ContactEdit
        data={person}
        show={contactEdit}
        add={isAddContactMode}
        handleClose={() => {
          callback();
          setContactEdit(false);
          setIsAddContactMode(false);
        }}
        currentContactsIndex={currentContactsIndex}
      />
      <JobEdit
        data={person}
        show={jobEdit}
        add={isAddJobMode}
        handleClose={() => {
          callback();
          setJobEdit(false);
          setIsAddJobMode(false);
        }}
        currentJobsIndex={currentJobsIndex}
      />
      <Form>
        <Row
          className={styles.group}
          onClick={(e) => {
            e.preventDefault();
            setDatosGeneralesEdit(true);
          }}
        >
          <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
          <Row className="mb-2">
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Nombres
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={person.first_name}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Apellidos
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={person.last_name}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Tipo de documento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={person.document_type}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Nro. Documento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={person.document_number}
                disabled
              />
            </Col>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label} as={Col}>
                Fecha de Nacimiento
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={person.birth_date}
                disabled
              />
            </Col>
          </Row>
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setDirectionEdit(true);
              setIsAddMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel}>
                  Direcciones{" "}
                  {person.addresses.length > 0 && (
                    <>
                      {" "}
                      {currentAddressIndex + 1} de {person.addresses.length}
                    </>
                  )}
                </Form.Label>
              </Col>

              {person.addresses.length > 0 && (
                <>
                  <Col md="auto">
                    <button
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setItemToDelete(person.addresses[currentAddressIndex]);
                        setDeleteContext("address");
                        setConfirmDelete(true);
                      }}
                      title="Eliminar"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </Col>
                </>
              )}
              <Col md="auto">
                <button
                  className={styles.addButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsAddMode(true);
                    setDirectionEdit(true);
                  }}
                  title="Agregar"
                >
                  <FiPlus size={20} />
                </button>
              </Col>
            </Row>
            {person.addresses.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Departamento
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.department}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Ciudad
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.city}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Barrio
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.neighborhood}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Calle principal
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.principal_street}
                      disabled
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Calle secundaria
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.secondary_street}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Número de residencia
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.house_number}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Descripción
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentAddress.description}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Tipo de dirección
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={
                          currentAddress.address_type === "HOME"
                          ? "Domicilio"
                          : currentAddress.address_type === "LABORAL"
                          ? "Laboral"
                          : currentAddress.address_type
                        }
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {person.addresses.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handlePrevious}
                  disabled={currentAddressIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handleNext}
                  disabled={currentAddressIndex === person.addresses.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setContactEdit(true);
              setIsAddContactMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel} as={Col}>
                  Contactos{" "}
                  {person.contacts.length > 0 && (
                    <>
                      {" "}
                      {currentContactsIndex + 1} de {person.contacts.length}
                    </>
                  )}
                </Form.Label>
              </Col>

              {person.contacts.length > 0 && (
                <>
                  <Col md="auto">
                    <button
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setItemToDelete(person.contacts[currentContactsIndex]);
                        setDeleteContext("contact");
                        setConfirmDelete(true);
                      }}
                      title="Eliminar"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </Col>
                </>
              )}
              <Col md="auto">
                <button
                  className={styles.addButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setContactEdit(true);
                    setIsAddContactMode(true);
                  }}
                  title="Agregar"
                >
                  <FiPlus size={20} />
                </button>
              </Col>
            </Row>
            {person.contacts.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      {currentContact.contact_type === "EMAIL" ? "Email" : "Número de teléfono"}
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentContact.contact_number}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      {currentContact.contact_type === "EMAIL" ? "Tipo de email" : "Tipo de teléfono"}
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={
                        currentContact.especific_type === "PERSONAL"
                        ? "Personal"
                        : currentContact.especific_type === "LABORAL"
                        ? "Laboral"
                        : currentContact.especific_type|| "N/A"
                      }
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Verificación
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={
                        currentContact.verified ? "Verificado" : "No verificado"
                      }
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {person.contacts.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handlePreviousContacts}
                  disabled={currentContactsIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handleNextContacts}
                  disabled={currentContactsIndex === person.contacts.length - 1}
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>

        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setJobEdit(true);
              setIsAddJobMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel} as={Col}>
                  Datos Laborales{" "}
                  {person.person_institutions.length > 0 && (
                    <>
                      {" "}
                      {currentJobsIndex + 1} de{" "}
                      {person.person_institutions.length}
                    </>
                  )}
                </Form.Label>
              </Col>

              {person.person_institutions.length > 0 && (
                <>
                  <Col md="auto">
                    <button
                      className={styles.deleteButton}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setItemToDelete(
                          person.person_institutions[currentJobsIndex]
                        );
                        setDeleteContext("job");
                        setConfirmDelete(true);
                      }}
                      title="Eliminar"
                    >
                      <FiTrash2 size={20} />
                    </button>
                  </Col>
                </>
              )}
              <Col md="auto">
                <button
                  className={styles.addButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setJobEdit(true);
                    setIsAddJobMode(true);
                  }}
                  title="Agregar"
                >
                  <FiPlus size={20} />
                </button>
              </Col>
            </Row>
            {person.person_institutions.length > 0 && (
              <>
                <Row style={{ marginBottom: "1rem" }}>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Institución Laboral
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentJob?.institution?.name || ""}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Rol
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentJob?.rol || ""}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Descripción del rol
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentJob?.rol_description || ""}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha de ingreso
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentJob?.from_date || ""}
                      disabled
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label bsPrefix={styles.label} as={Col}>
                      Fecha de salida
                    </Form.Label>
                    <Form.Control
                      bsPrefix={styles.input}
                      value={currentJob?.to_date || ""}
                      disabled
                    />
                  </Col>
                </Row>
              </>
            )}
          </Row>
          {person.person_institutions.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handlePreviousJobs}
                  disabled={currentJobsIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handleNextJobs}
                  disabled={
                    currentJobsIndex === person.person_institutions.length - 1
                  }
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
      <Modal show={confirmDelete} onHide={() => setConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteContext === "address" &&
            "¿Está seguro de que desea eliminar esta dirección? Esta acción no se puede deshacer."}
          {deleteContext === "contact" &&
            "¿Está seguro de que desea eliminar este contacto? Esta acción no se puede deshacer."}
          {deleteContext === "job" &&
            "¿Está seguro de que desea eliminar estos datos laborales? Esta acción no se puede deshacer."}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setConfirmDelete(false)}>
            Cancelar
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              if (deleteContext === "address") {
                deleteAddress(e, itemToDelete.addressId);
              } else if (deleteContext === "contact") {
                deleteContact(e, itemToDelete.contactId);
              } else if (deleteContext === "job") {
                deletePersonInstitution(e, itemToDelete.person_institutionId);
              }
              setConfirmDelete(false);
            }}
          >
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Information;
