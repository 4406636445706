import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "../../causas/Causas/Causas.module.css";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Causa from "../../causas/Causas/Causa/Causa";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../../Keycloak";
import { FaFileCirclePlus } from "react-icons/fa6";

const ConsultarDeposito = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [deposits, setdeposits] = useState([
    {
      storage_id: 0,
      name: "string",
      storage_handler: "string",
      location: "string",
    },
    {
      storage_id: 1,
      name: "string2",
      storage_handler: "string2",
      location: "string2",
    },
  ]);
  const [name, setName] = useState("");
  const [storageHandler, setStorageHandler] = useState("");
  const [location, setLocation] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [sorting, setSorting] = useState([]);
  const [totalElements, setTotalElements] = useState(0);

  const navigate = useNavigate();

  const [prosecutors, setProsecutors] = useState([]);

  const { keycloak } = useKeycloak();

  const [view, setView] = useState("T");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(10);

  const reset = (e) => {
    e.preventDefault();
    setPage(1);
    setName("");
    setStorageHandler("");
    setLocation("");
    setSorting([]);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
        "Upgrade-Insecure-Requests": 1,
      },
    };
  };

  const findDeposits = () => {
    setLoading(true);
    let params = buildParams();
    const URL = `${process.env.REACT_APP_API_URL}/evidence-storage?page=${page}&pageSize=${pageSize}${params}`;
    axios
      .get(URL, prepareHeaders())
      .then((res) => {
        //setdeposits(res.status == 206 ? res.data.content : []);
        setTotalPages(res.status == 206 ? res.data.totalPages : 10);
        setTotalElements(res.status == 206 ? res.data.totalElements : 0);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching deposits:", err);
        //setdeposits([]);
        setLoading(false);
      });
  };

  const buildParams = () => {
    let query = "";
    if (name && name != "") {
      query += `&name=${name}`;
    }
    if (storageHandler) {
      query += `&storage_handler=${storageHandler}`;
    }
    if (location) {
      query += `&location=${location}`;
    }
    if (sorting && sorting.length > 0) {
      sorting.map((sort) => {
        query += `&sortBy=${sort.key}:${sort.direction}`;
      });
    }
    return query;
  };

  const sortingBy = (field, label) => {
    if (sorting.filter((element) => element.key === field).length > 0) {
      setSorting(sorting.filter((element) => element.key !== field));
    } else {
      setSorting([...sorting, { key: field, label, direction: "ASC" }]);
    }
  };

  const changeDirection = (sort) => {
    let elements = [];
    for (let current of sorting) {
      if (current.key === sort.key) {
        current.direction = sort.direction === "ASC" ? "DESC" : "ASC";
      }
      elements.push(current);
    }
    setSorting(elements);
  };

  const callback = () => {
    findDeposits();
  };

  useEffect(() => findDeposits(), [page, pageSize, sorting]);
  useEffect(() => {
    findDeposits();
  }, []);
  useEffect(() => setPage(1), [name, storageHandler, location]);

  return (
    <>
      <Toaster />
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={3}>
          <div className={styles.sideBar}>
            <Form>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingName"
                      type="text"
                      placeholder="Nombre del depósito"
                      className={styles.inputFloating}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <label htmlFor="floatingName" className={styles.label}>
                      Nombre
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingStorageHandler"
                      type="text"
                      placeholder="Encargado"
                      className={styles.inputFloating}
                      value={storageHandler}
                      onChange={(e) => setStorageHandler(e.target.value)}
                    />
                    <label
                      htmlFor="floatingStorageHandler"
                      className={styles.label}
                    >
                      Encargado
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>

              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingLocation"
                      type="text"
                      placeholder="Ubicación"
                      className={styles.inputFloating}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                    <label htmlFor="floatingLocation" className={styles.label}>
                      Ubicación
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: "1rem" }}>
                <Col
                  style={{
                    textAlign: "right",
                    marginTop: "auto",
                    display: "flex",
                  }}
                >
                  <DropdownButton
                    bsPrefix={styles.secondary}
                    title="Ordenar"
                    style={{ marginLeft: "auto" }}
                  >
                    <Dropdown.Item
                      onClick={(e) => sortingBy("name", "Nombre")}
                      className={styles.item}
                    >
                      Nombre de depósito
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={(e) => sortingBy("storage_handler", "Encargado")}
                      className={styles.item}
                    >
                      Encargado
                    </Dropdown.Item>
                  </DropdownButton>
                  &nbsp;&nbsp;
                  <Button onClick={reset} bsPrefix={styles.primary}>
                    Limpiar <AiOutlineClear />
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => findDeposits()}
                    bsPrefix={styles.primary}
                  >
                    Buscar <AiOutlineSearch />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col md={9}>
          <div className={styles.container}>
            <h2>Depósitos Fiscales</h2>
            <Button
              onClick={(e) => {
                e.preventDefault();
                navigate("/depositos/registrar");
              }}
              bsPrefix={styles.newPrimary}
              style={{ marginLeft: "auto" }}
            >
              &nbsp;&nbsp;Nuevo depósito &nbsp;&nbsp;
              <FaFileCirclePlus />
              &nbsp;&nbsp;
            </Button>
            <br />
            {loading ? (
              <span className="mt-3 mb-2">Buscando...</span>
            ) : !loading && (!deposits || deposits.length == 0) ? (
              <span className="mt-3 mb-2">No hay resultados</span>
            ) : (
              <>
                <Row style={{ flexDirection: "row-reverse" }}>
                  <Col md="auto" style={{ textAlign: "right" }}>
                    <InputGroup
                      style={{
                        flexWrap: "wrap",
                        alignItems: "stretch",
                        justifyContent: "flex-end",
                      }}
                    >
                      <RiTableFill
                        style={{ marginTop: "auto" }}
                        className={
                          view == "T" ? styles.iconActive : styles.iconInactive
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setView("T");
                          setPageSize(20);
                          setPage(1);
                        }}
                      />
                      &nbsp;
                    </InputGroup>
                  </Col>
                  <Col md="auto" style={{ textAlign: "right" }}>
                    {sorting.map((sort) => (
                      <>
                        <Badge className={styles.sortItem}>
                          {sort.label}
                          &nbsp;
                          {sort.direction === "ASC" ? (
                            <RiSortAsc
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                changeDirection(sort);
                              }}
                            />
                          ) : (
                            <RiSortDesc
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                changeDirection(sort);
                              }}
                            />
                          )}
                          &nbsp;
                          <RiCloseFill
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              sortingBy(sort.key);
                            }}
                          />
                        </Badge>
                        &nbsp;
                      </>
                    ))}
                  </Col>
                </Row>
                <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem" }}>
                  <Col md={6}>
                    <CustomPagination
                      page={page}
                      pageSize={pageSize}
                      changePageSize={setPageSize}
                      toPage={(page) => {
                        setPage(page);
                      }}
                      totalPages={totalPages}
                    />
                  </Col>
                  <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                    <span>
                      {totalElements}{" "}
                      {totalElements > 1 ? "depósitos" : "depósito"}
                    </span>
                  </Col>
                </Row>
                {view == "T" ? (
                  <Table className={styles.table}>
                    <thead>
                      <th>Nombre del depósito</th>
                      <th>Encargado</th>
                      <th>Ubicación</th>
                      <th></th>
                    </thead>
                    <tbody>
                      {deposits.map((deposit) => (
                        <tr>
                          <td>{deposit.name}</td>
                          <td>{deposit.storage_handler}</td>
                          <td>{deposit.location}</td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <Button
                                bsPrefix={styles.next}
                                onClick={() =>
                                  navigate(`/depositos/${deposit.storage_id}`)
                                }
                              >
                                VER
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ConsultarDeposito;
