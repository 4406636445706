import React, { useState } from 'react';
import { Form, Button, Col, Row } from "react-bootstrap";
import styles from '../Institucion.module.css';

const DatosGenerales = ({ nextStep, updateDatosGenerales }) => {
  const [institutionName, setInstitutionName] = useState('');
  const [institutionRuc, setInstitutionRuc] = useState('');
  const [institutionType, setInstitutionType] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
    if (!institutionName.trim()) {
      newErrors.name = 'El nombre de la institución es requerido';
    }
    
    if (!institutionRuc.trim()) {
      newErrors.ruc = 'El RUC es requerido';
    } 
    
    if (!institutionType) {
      newErrors.type = 'El tipo de institución es requerido';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      updateDatosGenerales({
        name: institutionName,
        ruc: institutionRuc,
        institution_type: institutionType
      });
      nextStep();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col>
          <Form.Label className={styles.groupLabel}>
            Información básica
          </Form.Label>
        </Col>
      </Row>

      <Row>
        <Form.Group as={Col}>
          <Form.Floating>
            <Form.Control
              id="institution-name"
              type="text"
              value={institutionName}
              onChange={(e) => setInstitutionName(e.target.value)}
              placeholder="Ingrese el nombre de la institución"
              className={`${styles.input} ${errors.name ? 'is-invalid' : ''}`}
            />
            <label htmlFor="institution-name" className={styles.label}>
              Nombre de la Institución
            </label>
            {errors.name && <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>}
          </Form.Floating>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Floating>
            <Form.Control
              id="institution-ruc"
              type="text"
              value={institutionRuc}
              placeholder="Ingrese el número de RUC"
              onChange={(e) => setInstitutionRuc(e.target.value)}
              className={`${styles.input} ${errors.ruc ? 'is-invalid' : ''}`}
            />
            <label htmlFor="institution-ruc" className={styles.label}>
              Número de RUC
            </label>
            {errors.ruc && <Form.Control.Feedback type="invalid">{errors.ruc}</Form.Control.Feedback>}
          </Form.Floating>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Floating>
            <Form.Control
              as="select"
              id="institution-type"
              className={`${styles.input} ${errors.type ? 'is-invalid' : ''}`}
              value={institutionType}
              onChange={(e) => setInstitutionType(e.target.value)}
            >
              <option value="" selected>Seleccione...</option>
              <option value="PUBLIC">Público</option>
              <option value="PRIVATE">Privado</option>
            </Form.Control>
            <label htmlFor="institution-type" className={styles.label}>
              Tipo de Institución
            </label>
            {errors.type && <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>}
          </Form.Floating>
        </Form.Group>
      </Row>

      <Row className="mt-4" style={{ display: "flex", paddingTop: ".7rem" }}>
        <Col md={6} className="ms-auto text-end">
          <Button bsPrefix={styles.next} type="submit">
            SIGUIENTE
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default DatosGenerales;