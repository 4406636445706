import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "../DatosGenerales/DatosGeneralesEdit.module.scss";
import {Autocomplete, TextField} from "@mui/material";


const DireccionEdit = ({ show, handleClose, callback, data }) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [city, setCity] = useState(undefined);
  const [department, setDepartment] = useState(undefined);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [principalStreet, setPrincipalStreet] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [coordinates, setCoordinates] = useState("");

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => setCities(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const handleChangeNeighborhood = (event, value) => {
    event.preventDefault();
    setNeighborhood(typeof value === "object" ? value.neighborhood : value);
    setErrors({ ...errors, neighborhood: "" });
  };

  useEffect(() => {
    findDeparments();
  }, []);
  useEffect(() => {
    if (department != undefined) {
      findCities(department);
    }
  }, [department]);
  useEffect(() => {
    if (city != undefined) {
      findNeighborhoods(city);
    }
  }, [city]);

  useEffect(() => {
    if (data) {
      setDepartment(data.address.department);
      setCity(data.address.city);
      setNeighborhood(data.address.neighborhood);
      setDescription(data.address.description);
      setCountry(data.address.country);
      setPrincipalStreet(data.address.principalStreet);
      setSecondaryStreet(data.address.secondaryStreet);
      setHouseNumber(data.address.houseNumber);
      setCoordinates(data.address.setCoordinates);
    }
  }, [data]);

  const buildBody = () => {
    return {
      department,
      city,
      neighborhood,
      description,
      country,
      principalStreet,
      secondaryStreet,
      houseNumber,
      coordinates,
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/institutions/${data.institution_id}/address/${data.address.addressId}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dirección</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    as="select"
                    id="departmentField"
                    className={`${styles.input} ${
                      errors.department ? "is-invalid" : ""
                    }`}
                    value={department}
                    onChange={(e) => {
                      setDepartment(e.target.value);
                      setErrors({ ...errors, department: "" });
                    }}
                  >
                    <option value="" selected>
                      Seleccione...
                    </option>
                    {departments.map((current) => (
                      <option key={current.id} value={current.id}>
                        {current.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label htmlFor="department" className={styles.label}>
                    Departamento
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    as="select"
                    id="cityField"
                    className={`${styles.input} ${
                      errors.city ? "is-invalid" : ""
                    }`}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value);
                      setErrors({ ...errors, city: "" });
                    }}
                  >
                    <option value="" selected>
                      Seleccione...
                    </option>
                    {cities.map((current) => (
                      <option key={current.id} value={current.id}>
                        {current.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Label htmlFor="city" className={styles.label}>
                    Ciudad
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Autocomplete
                    options={neighborhoods}
                    getOptionLabel={(option) =>
                      typeof option == "object" ? option.name : option
                    }
                    value={JSON.stringify(neighborhood)}
                    freeSolo
                    autoSelect
                    onChange={handleChangeNeighborhood}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Barrio / Localidad"
                        classname={styles.input}
                      />
                    )}
                  />
                </Form.Floating>
              </Form.Group>
            </Row>

            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="description1"
                    type="text"
                    required={!preCarga}
                    value={description}
                    className={styles.input}
                    placeholder="Ingrese la descripción"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Form.Label htmlFor="description1" className={styles.label}>
                    Descripción
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="country1"
                    type="text"
                    required={!preCarga}
                    value={country}
                    className={styles.input}
                    placeholder="Ingrese el país"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                  <Form.Label htmlFor="country1" className={styles.label}>
                    País
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="description2"
                    type="text"
                    required={!preCarga}
                    value={principalStreet}
                    className={styles.input}
                    placeholder="Ingrese la descripción"
                    onChange={(e) => setPrincipalStreet(e.target.value)}
                  />
                  <Form.Label htmlFor="description2" className={styles.label}>
                    Calle Principal
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="country2"
                    type="text"
                    required={!preCarga}
                    value={secondaryStreet}
                    className={styles.input}
                    placeholder="Ingrese el país"
                    onChange={(e) => setSecondaryStreet(e.target.value)}
                  />
                  <Form.Label htmlFor="country2" className={styles.label}>
                    Calle Secundaria
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="country3"
                    type="text"
                    required={!preCarga}
                    value={houseNumber}
                    className={styles.input}
                    placeholder="Ingrese el país"
                    onChange={(e) => setHouseNumber(e.target.value)}
                  />
                  <Form.Label htmlFor="country3" className={styles.label}>
                    Número de casa
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="country4"
                    type="text"
                    required={!preCarga}
                    value={coordinates}
                    className={styles.input}
                    placeholder="Ingrese el país"
                    onChange={(e) => setCoordinates(e.target.value)}
                  />
                  <Form.Label htmlFor="country4" className={styles.label}>
                    Coordenadas
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>Actualizando...</span>
              </>
            ) : (
              <span>Guardar</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DireccionEdit;
