import React, { useEffect, useState } from "react";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { useParams, Link } from "react-router-dom";
import { Form, Col, Row } from "react-bootstrap";
import Information from "./Information/Information";
import styles from "../../../causas/Causas/Causas.module.css";

const InstitutionContext = () => {
  const { keycloak } = useKeycloak();

  let { id } = useParams();

  const [institution, setInstitution] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState("information");

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  const getInstitution = () => {
    console.log("Buscando institucion con id: " + id);
    setLoading(true);
    const URL = `${process.env.REACT_APP_API_URL}/institutions/${id}`;
    axios
      .get(URL, prepareHeaders())
      .then((res) => {
        setInstitution(res.status === 200 ? res.data : undefined);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setInstitution(undefined);
        setLoading(false);
      });
  };

  useEffect(() => { if (id) getInstitution();}, [id]);
  useEffect(() => { if (id) getInstitution();}, []);

  return institution ? (
    <>
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
        <Col md={2}>
          <div className={styles.sideBar}>
            <Form className={styles.filterContainer}> 
              <Row>
                <Col style={{ textAlign: "right"}}>
                  <Form.Label className={styles.id}>{`Nº ${institution.institution_id}`}</Form.Label>
                </Col>
              </Row>
            </Form>
            <Link
              className={`${styles.button} ${
                menu === "information" ? styles.active : ""
              }`}
              onClick={(e) => {
                console.log("Institution recibida: " + JSON.stringify(institution));
                setMenu("information");
              }}
            >
              <span>Información</span>
            </Link>
          </div>
        </Col>
        <Col md={10}>
          <div className={styles.container}>
              <Information
                institution={institution}
                setInstitution={setInstitution}
                callback={() => getInstitution()}
              />
          </div>
        </Col>
      </Row>
    </>
  ) : (
    <>
      <h1> No se encontro la institucion </h1>
    </>
  );
};

export default InstitutionContext;
