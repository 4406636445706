import React, { useState } from "react";
import Stepper from "../../components/Stepper";
import Step from "../../components/Stepper/Step";
import styles from "./Institucion.module.css";
import DatosUbicacion from "./DatosUbicacion/DatosUbicacion";
import DatosGenerales from "./DatosGenerales/DatosGenerales";
import DatosContacto from "./DatosContacto/DatosContacto";
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const InstitucionForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const { keycloak } = useKeycloak();
  
  const [institution, setInstitution] = useState({
    name: '',
    ruc: '',
    institution_type: '',
    address: {},
    contacts: []
  });

  const prepareHeaders = () => {
    return {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    console.log(institution);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const updateDatosGenerales = (datosGenerales) => {
    setInstitution({...institution, ...datosGenerales});
  };

  const updateDatosUbicacion = (datosUbicacion) => {
    setInstitution({...institution, address: {...institution.address, ...datosUbicacion}});
  };

  const updateDatosContactos = (datosContactos) => {
    setInstitution({...institution, contacts: datosContactos});
  };

  const handleSave = () => {
    console.log("Datos guardados:", institution);
    axios.post(process.env.REACT_APP_API_URL + "/institutions", institution, prepareHeaders())
      .then(response => { console.log(response); toast.success("Datos guardados correctamente", { position: "top-center" }); navigate('/institucion') })
      .catch(error => console.error(error));
  };

  const createSteps = () => {
    return [
      { label: 'Datos generales', code: '1', id: 1 },
      { label: 'Datos de ubicación', code: '2', id: 2 },
      { label: 'Datos de contacto', code: '3', id: 3 }
    ]
  }

  const steps = createSteps();

  return (
    <>
    <Toaster />
    <div className={styles.container}>
      <Stepper steps={steps} current={currentStep}>
        <Step step={1}>
          <DatosGenerales
            nextStep={handleNextStep} 
            updateDatosGenerales={updateDatosGenerales}
          />
        </Step>
        <Step step={2}>
          <DatosUbicacion
            prevStep={handlePrevStep} 
            nextStep={handleNextStep} 
            updateDatosUbicacion={updateDatosUbicacion}
          />
        </Step>
        <Step step={3}>
          <DatosContacto 
            prevStep={handlePrevStep} 
            updateDatosContactos={updateDatosContactos}
            handleSave={handleSave}
          />
        </Step>
      </Stepper>
    </div>
    </ >
  );
};

export default InstitucionForm;