import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Badge, Col, Row } from "react-bootstrap";
import styles from "./JobModal.module.css";

const JobModal = ({ data, show, handleClose }) => {
  const [institution, setInstitution] = useState(null);
  console.log(data);
  return data ? (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Datos laborales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="institutionIdValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingInstitutionId"
                    type="text"
                    value={data.institution?.name || "No especificada"}
                    className={styles.input}
                  />
                  <label
                    htmlFor="floatingInstitutionId"
                    className={styles.label}
                  >
                    Institucion
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col} controlId="rolValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingRol"
                    type="text"
                    value={data.rol}
                    className={styles.input}
                  />
                  <label htmlFor="floatingRol" className={styles.label}>
                    Tipo de contacto
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col} controlId="rolDescriptionValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingRolDescription"
                    type="text"
                    value={data.rol_description}
                    className={styles.input}
                  />
                  <label
                    htmlFor="floatingRolDescription"
                    className={styles.label}
                  >
                    Descripción
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col} controlId="fromDateValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingFromDate"
                    type="text"
                    value={data.from_date}
                    className={styles.input}
                  />
                  <label htmlFor="floatingFromDate" className={styles.label}>
                    Fecha de ingreso
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col} controlId="toDateValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingToDate"
                    type="text"
                    value={data.to_date}
                    className={styles.input}
                  />
                  <label htmlFor="floatingToDate" className={styles.label}>
                    Fecha de salida
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export default JobModal;
