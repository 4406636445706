import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "../Personas.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../../Keycloak";
import Direcciones from "../Direccion/Direcciones";
import Contactos from "../Contacto/Contactos";

const Personas = ({
  person,
  updatePerson,
  errors,
  nextStep,
  setViewPerson,
  viewPerson
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [documentType, setDocumentType] = useState("CI");
  const [documentNumber, setDocumentNumber] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [profession, setProfession] = useState("");
  const [alias, setAlias] = useState("");
  const [persona, setPersona] = useState([]);

  const { keycloak } = useKeycloak();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const getFormattedDate = (dateString) => {
    if (!dateString) return "";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const newErrores = {
      documentType: "",
      documentNumber: "",
      firstName: "",
      lastName: "",
    };

    if (!documentType) {
      newErrores.documentType = "Este campo es obligatorio";
    }
    if (!documentNumber) {
      newErrores.documentNumber = "Este campo es obligatorio";
    }
    if (!firstName) {
      newErrores.firstName = "Este campo es obligatorio";
    }
    if (!lastName) {
      newErrores.lastName = "Este campo es obligatorio";
    }

    if (
      newErrores.documentType ||
      newErrores.documentNumber ||
      newErrores.firstName ||
      newErrores.lastName
    ) {
      return;
    }

    const person = {
      document_type: documentType,
      document_number: documentNumber,
      first_name: firstName,
      last_name: lastName,
      birth_date: getFormattedDate(birthDate),
      gender,
      marital_status: maritalStatus,
      profession,
      alias,
    };

    setValidated(true);
    updatePerson(person);
    nextStep();
  };

  useEffect(() => {
    if (viewPerson && person) {
      setDocumentType(person.document_type || "");
      setDocumentNumber(person.document_number || "");
      setFirstName(person.first_name || "");
      setLastName(person.last_name || "");
      setBirthDate(getFormattedDate(person.birth_date));
      setGender(person.gender || "");
      setMaritalStatus(person.marital_status || "");
      setProfession(person.profession || "");
      setAlias(person.alias || "");
    }
    console.log(viewPerson);
  }, [viewPerson, person]);

  return (
    <>
      <Form validated={validated} onSubmit={handleSubmit}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Row className="mb-4">
            <Form.Label className={styles.groupLabel}>
              Documento de identidad
            </Form.Label>
            <Form.Group as={Col}>
              <Form.Floating>
                <Form.Control
                  id="document_type"
                  as="select"
                  value={documentType || ""}
                  className={styles.input}
                  onChange={(e) => {
                    setDocumentType(e.target.value);
                    setDocumentNumber("");
                  }}
                  placeholder="Ingrese el tipo de documento"
                >
                  <option value="">Seleccione tipo de Documento</option>
                  <option value="CI">Cédula de identidad</option>
                  <option value="PAS">Pasaporte</option>
                  <option value="MAT">
                    Matrícula de la Corte Suprema de Justicia
                  </option>
                </Form.Control>
                <label htmlFor="document_type" className={styles.label}>
                  Tipo de documento
                </label>
              </Form.Floating>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Floating>
                <Form.Control
                  id="document_number"
                  type="text"
                  value={documentNumber || ""}
                  placeholder="Ingrese el número de documento"
                  className={styles.input}
                  onChange={(e) => {
                    setDocumentNumber(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (documentType === "CI" && !/^[0-9]*$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <label htmlFor="document_number" className={styles.label}>
                  Número de documento
                </label>
              </Form.Floating>
            </Form.Group>
          </Row>
          <Row className="mb-4">
            <Form.Label className={styles.groupLabel}>
              Datos personales
            </Form.Label>
            <Form.Group as={Col}>
              <Form.Floating>
                <Form.Control
                  required
                  id="first_name"
                  type="text"
                  value={firstName || ""}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  placeholder="Ingrese el nombre de la persona"
                  className={styles.input}
                />
                <label className={styles.label} htmlFor="first_name">
                  Nombres
                </label>
              </Form.Floating>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Floating>
                <Form.Control
                  required
                  id="last_name"
                  value={lastName || ""}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  className={styles.input}
                  type="text"
                  placeholder="Ingrese el apellido de la persona"
                />
                <label className={styles.label} htmlFor="last_name">
                  Apellidos
                </label>
              </Form.Floating>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Floating>
                <Form.Control
                  id="birth_date"
                  required={false}
                  value={birthDate || ""}
                  onChange={(e) => {
                    setBirthDate(e.target.value);
                  }}
                  className={styles.input}
                  placeholder="Ingrese la fecha de nacimiento"
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                />
                <label htmlFor="birth_date" className={styles.label}>
                  Fecha de nacimiento
                </label>
              </Form.Floating>
            </Form.Group>
            <Row className="mt-4 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="gender"
                    className={styles.input}
                    as="select"
                    value={gender}
                    onFocus={(e) => {
                      if (e.target.options[0].value === "") {
                        e.target.options[0].text = "Ninguno";
                      }
                    }}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <option value="">Seleccione</option>
                    <option value="F">Femenino</option>
                    <option value="M">Masculino</option>
                  </Form.Control>
                  <label htmlFor="gender" className={styles.label}>
                    Genero
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="marital_status"
                    className={styles.input}
                    as="select"
                    value={maritalStatus}
                    onFocus={(e) => {
                      if (e.target.options[0].value === "") {
                        e.target.options[0].text = "Ninguno";
                      }
                    }}
                    onChange={(e) => {
                      setMaritalStatus(e.target.value);
                    }}
                  >
                    <option value="">Seleccione</option>
                    <option value="Casado">Casado/a</option>
                    <option value="Soltero">Soltero/a</option>
                    <option value="Viudo">Viudo/a</option>
                  </Form.Control>
                  <label htmlFor="marital_status" className={styles.label}>
                    Estado Civil
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="profession"
                    type="text"
                    value={profession}
                    onChange={(e) => {
                      setProfession(e.target.value);
                    }}
                    placeholder="Ingrese la profesion de la persona"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="profession">
                    Profesión
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mt-2 mb-4">
              <Col md={4}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="alias"
                      type="text"
                      value={alias}
                      onChange={(e) => {
                        setAlias(e.target.value);
                      }}
                      placeholder="Ingrese el alias de la persona"
                      className={styles.input}
                    />
                    <label className={styles.label} htmlFor="alias">
                      Alias
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Col>
            </Row>
          </Row>

          <Row
            className="mt-4"
            style={{ display: "flex", paddingTop: ".7rem" }}
          >
            <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
              <Button bsPrefix={styles.next} type="submit">
                SIGUIENTE
              </Button>
            </Col>
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default Personas;
