import React from "react";
import { Modal, Button, Form, Badge, Col, Row } from "react-bootstrap";
import styles from "./DirectionModal.module.css";

const DirectionModal = ({ data, show, handleClose }) => {
  console.log(data);
  return data ? (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Dirección</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/*<Row className="mt-2 mb-4"> 
                            <Col>
                                <Badge>{data.type ? ComplainantType[data.type].name : "Ciudadano"}</Badge>
                            </Col>
                        </Row>*/}
          {data.department && data.city ? (
            <Row className="mb-2">
              {data.department ? (
                <Col>
                  <Badge>{data.department}</Badge>
                </Col>
              ) : (
                <></>
              )}
              {data.city ? (
                <Col>
                  <Badge>{data.city}</Badge>
                </Col>
              ) : (
                <></>
              )}
              {data.neighborhood ? (
                <Col>
                  <Badge>{data.neighborhood}</Badge>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="principalStreetValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingPrincipalStreet"
                    type="text"
                    value={data.principal_street}
                    className={styles.input}
                  />
                  <label
                    htmlFor="floatingPrincipalStreet"
                    className={styles.label}
                  >
                    Calle principal
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col} controlId="secondaryStreetValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingSecondaryStreet"
                    type="text"
                    value={data.secondary_street}
                    className={styles.input}
                  />
                  <label
                    htmlFor="floatingSecondaryStreet"
                    className={styles.label}
                  >
                    Calle secundaria
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col} controlId="houseNumberValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingHouseNumber"
                    type="number"
                    value={data.house_number}
                    className={styles.input}
                  />
                  <label htmlFor="floatingHouseNumber" className={styles.label}>
                    Número de residencia
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col} controlId="descriptionValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingDescription"
                    type="text"
                    value={data.description}
                    className={styles.input}
                  />
                  <label htmlFor="floatingDescription" className={styles.label}>
                    Descripción
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export default DirectionModal;
