import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./DatosGeneralesEdit.module.scss";

const DatosGeneralesEdit = ({ show, handleClose, callback, data }) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [first_name, setFirstName] = useState(undefined);
  const [last_name, setLastName] = useState(undefined);
  const [document_number, setDocumentNumber] = useState(undefined);
  const [document_type, setDocumentType] = useState(undefined);
  const [birth_date, setBirthDate] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [marital_status, setMaritalStatus] = useState(undefined);
  const [profession, setProfession] = useState(undefined);
  const [alias, setAlias] = useState(undefined);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  useEffect(() => {
    if (data) {
      setDocumentType(data.document_type);
      setDocumentNumber(data.document_number);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setBirthDate(data.birth_date);
      setGender(data.gender);
      setMaritalStatus(data.marital_status);
      setProfession(data.profession);
      setAlias(data.alias);
    }
  }, [data]);

  const buildBody = () => {
    return {
      document_type,
      document_number,
      first_name,
      last_name,
      birth_date,
      gender,
      marital_status,
      profession,
      alias,
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/person/${data.personId}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos Generales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group
                className="mb-3"
                as={Col}
                controlId="firstNameValidation"
              >
                <Form.Floating>
                  <Form.Control
                    id="floatingFirstName"
                    required={!preCarga}
                    type="text"
                    value={first_name}
                    className={styles.input}
                    placeholder="Ingrese el nombre"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                  <Form.Label
                    htmlFor="floatingFirstName"
                    className={styles.label}
                  >
                    Nombres
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col} controlId="lastNameValidation">
                <Form.Floating>
                  <Form.Control
                    id="floatingLastName"
                    required={!preCarga}
                    type="text"
                    value={last_name}
                    className={styles.input}
                    placeholder="Ingrese el apellido"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                  <Form.Label
                    htmlFor="floatingLastName"
                    className={styles.label}
                  >
                    Apellidos
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="documentTypeValidation">
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentType"
                      as="select"
                      value={document_type}
                      className={styles.input}
                      placeholder="Ingrese el tipo de documento"
                    >
                      <option value="">Seleccione tipo de Documento</option>
                      <option value="CI">Cédula de identidad</option>
                      <option value="PAS">Pasaporte</option>
                      <option value="MAT">
                        Matrícula de la Corte Suprema de Justicia
                      </option>
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingDocumentType"
                      className={styles.label}
                    >
                      Tipo de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group as={Col} controlId="documentNumberValidation">
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentNumber"
                      required={!preCarga}
                      type="text"
                      value={document_number}
                      className={styles.input}
                      placeholder="Ingrese el numero de documento"
                    />
                    <Form.Label
                      htmlFor="floatingDocumentNumber"
                      className={styles.label}
                    >
                      Número de documento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="birthDateValidation">
                  <Form.Floating>
                    <Form.Control
                      id="floatingBirthDate"
                      required={!preCarga}
                      value={birth_date}
                      className={styles.input}
                      placeholder="Ingrese la fecha de nacimiento"
                      onChange={(e) => {
                        setBirthDate(e.target.value);
                      }}
                      readOnly
                      type="text"
                      max={new Date().toISOString().split("T")[0]}
                    />
                    <Form.Label
                      htmlFor="floatingBirthDate"
                      className={styles.label}
                    >
                      Fecha de nacimiento
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  controlId="genderValidation"
                >
                  <Form.Floating>
                    <Form.Control
                      id="floatingGender"
                      as="select"
                      value={gender}
                      className={styles.input}
                      placeholder="Ingrese el genero"
                      onChange={(e) => {
                        setGender(e.target.value);
                      }}
                    >
                      <option value="F">Femenino</option>
                      <option value="M">Masculino</option>
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingGender"
                      className={styles.label}
                    >
                      Genero
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="maritalStatusValidation">
                  <Form.Floating>
                    <Form.Control
                      id="floatingMaritalStatus"
                      as="select"
                      value={marital_status}
                      className={styles.input}
                      placeholder="Ingrese el estado civil"
                      onChange={(e) => {
                        setMaritalStatus(e.target.value);
                      }}
                    >
                      <option value="Casado">Casado/a</option>
                      <option value="Soltero">Soltero/a</option>
                      <option value="Viudo">Viudo/a</option>
                    </Form.Control>
                    <Form.Label
                      htmlFor="floatingMaritalStatus"
                      className={styles.label}
                    >
                      Estado civil
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Col}
                  controlId="professionValidation"
                >
                  <Form.Floating>
                    <Form.Control
                      id="floatingProfession"
                      required={!preCarga}
                      type="text"
                      value={profession}
                      className={styles.input}
                      placeholder="Ingrese la profesión"
                      onChange={(e) => {
                        setProfession(e.target.value);
                      }}
                    />
                    <Form.Label
                      htmlFor="floatingProfession"
                      className={styles.label}
                    >
                      Profesión
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col} controlId="aliasValidation">
                  <Form.Floating>
                    <Form.Control
                      id="floatingAlias"
                      required={!preCarga}
                      type="text"
                      value={alias}
                      className={styles.input}
                      placeholder="Ingrese el alias"
                      onChange={(e) => {
                        setAlias(e.target.value);
                      }}
                    />
                    <Form.Label
                      htmlFor="floatingAlias"
                      className={styles.label}
                    >
                      Alias
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>Actualizando...</span>
              </>
            ) : (
              <span>Guardar</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DatosGeneralesEdit;
