import React from "react";
import { Modal, Button, Form, Badge, Col, Row } from "react-bootstrap";
import styles from "./ContactModal.module.css";

const ContactModal = ({ data, show, handleClose }) => {
  console.log(data);
  return data ? (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Contacto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Row className="mb-2">
            <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingContactType"
                    type="text"
                    value={data.contact_type === "EMAIL" ? "Email" : "Teléfono"}
                    className={styles.input}
                  >
                  </Form.Control>
                  <label htmlFor="floatingContactType" className={styles.label}>
                    Tipo de contacto
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingContactNumber"
                    type="text"
                    value={data.contact_number}
                    className={styles.input}
                  />
                  <label
                    htmlFor="floatingContactNumber"
                    className={styles.label}
                  >
                    {data.contact_type === "EMAIL" ? "Email" : "Número de teléfono"}
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingEspecificType"
                    type="text"
                    value={data.especific_type}
                    className={styles.input}
                  >
                  </Form.Control>
                  <label htmlFor="floatingEspecificType" className={styles.label}>
                    {data.contact_type === "EMAIL" ? "Tipo de email" : "Tipo de teléfono"}
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingVerified"
                    as="select"
                    value={data.verified}
                    className={styles.input}
                  >
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </Form.Control>
                  <label htmlFor="floatingVerified" className={styles.label}>
                    Verificado
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <></>
  );
};

export default ContactModal;
