import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./ContactEdit.module.scss";

const ContactEdit = ({
  show,
  handleClose,
  callback,
  data,
  currentContactsIndex,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [contactNumber, setContactNumber] = useState(undefined);
  const [contactType, setContactType] = useState(undefined);
  const [especificType, setEspecificType] = useState(undefined);
  const [otherEspecificType, setOtherEspecificType] = useState("");
  const [verified, setVerified] = useState(undefined);

  const [contact, setContact] = useState({});
  const [contacts, setContacts] = useState([]);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  useEffect(() => {
    if (!add) {
      if (
        data &&
        data.contacts &&
        currentContactsIndex !== undefined &&
        data.contacts[currentContactsIndex] !== undefined
      ) {
        const currentContact = data.contacts[currentContactsIndex];
        setContact({
          contactNumber: currentContact.contact_number,
          contactType: currentContact.contact_type,
          especificType: currentContact.especific_type,
          verified: currentContact.verified,
        });

        setOtherEspecificType("");

        const currentContactsType = currentContact.especific_type || "";
        setEditMode(
          currentContactsType === "" || currentContactsType === "PERSONAL" || currentContactsType === "LABORAL"
        );

      }
    } else {
      const currentContact = data.contacts[currentContactsIndex];
      setContact({
        contactNumber: "",
        contactType: "",
        especificType: "",
        verified: "",
      });
      setOtherEspecificType("");
    }
  }, [data, currentContactsIndex, add]);

  const buildBody = () => {
    return {
      contact_number: contact.contactNumber,
      contact_type: contact.contactType,
      especific_type: contact.especificType === "OTHER" ? otherEspecificType : contact.especificType,
      verified: contact.verified,
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/person/${data.personId}/contact/${data.contacts[currentContactsIndex].contactId}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
        handleClose();
      });
  };

  const handleInputChange = (field, value) => {
    setContact((prevContact) => ({
      ...prevContact,
      [field]: value,
    }));
  };

  const handleSave = () => {
    if (!contact.contactType || !contact.contactNumber) {
      toast.error("Por favor seleccione el tipo de contacto e ingrese el email o telefóno.");
      return;
    }
    const URL = `${process.env.REACT_APP_API_URL}/person/${data.personId}/contact`;
    axios
      .post(URL, buildBody(), prepareHeaders())
      .then((res) => {
        toast.success("Contacto registrado exitosamente", {
          position: "top-center",
        });
        handleClose();
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar el contacto ", {
          position: "top-center",
        });
        console.log(err);
        handleClose();
      });
  };

  return (data.contacts[currentContactsIndex] !== undefined &&
    contact !== undefined) ||
    add == true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar Contacto" : "Editar Contacto"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Contacto {currentContactsIndex + 1}</h6>
            <Row className="mt-2 mb-4">
            <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    as="select"
                    value={contact.contactType}
                    className={styles.input}
                    placeholder="Ingrese el tipo de contacto"
                    onChange={(e) =>
                      handleInputChange("contactType", e.target.value)
                    }
                  >
                    <option value="EMAIL">Email</option>
                    <option value="TELEPHONE">Teléfono</option>
                  </Form.Control>
                  <Form.Label
                    htmlFor="floatingContactType"
                    className={styles.label}
                  >
                    Tipo de contacto
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    value={contact.contactNumber}
                    className={styles.input}
                    placeholder={ 
                      contact.contactType === "EMAIL" ? "Ingrese el email" : "Ingrese el número de teléfono"}
                    onChange={(e) =>
                      handleInputChange("contactNumber", e.target.value)
                    }
                  />
                  <Form.Label
                    htmlFor="floatingContactNumber"
                    className={styles.label}
                  >
                    {contact.contactType === "EMAIL" ? "Email" : "Número de teléfono"}
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Floating>
                {editMode ? (
                  <Form.Control
                    name="especific_type"
                    className={styles.input}
                    as="select"
                    value={contact.especificType} 
                    onChange={(e) =>  handleInputChange("especificType", e.target.value)}
                    required
                  >
                    <option value="PERSONAL">Personal</option>
                    <option value="LABORAL">Laboral</option>
                    <option value="OTHER">Otro</option>
                  </Form.Control>
                  ):(
                    <Form.Control
                    type="text"
                    value={
                      contact.especificType === "PERSONAL"
                      ? "Personal"
                      : contact.especificType === "LABORAL"
                      ? "Laboral"
                      : contact.especificType
                    }
                    className={styles.input}
                    placeholder="seleccione el tipo"
                    onClick={() => setEditMode(true)}
                    readOnly
                    />
                  )}
                  <label htmlFor="especific_type" className={styles.label}>
                    {contact.contactType === "EMAIL" ? "Tipo de email" : "Tipo de teléfono"}
                  </label>
                </Form.Floating>
                {contact.especificType === "OTHER" && (
                  <Form.Floating className="mt-3">
                    <Form.Control
                      name="other_especific_type_description"
                      className={styles.input}
                      type="text"
                      placeholder="Ingrese la descripción"
                      value={otherEspecificType}
                      onChange={(e) => setOtherEspecificType(e.target.value)}
                      required
                    />
                    <label htmlFor="other_especific_type_description" className={styles.label}>
                      Especifique
                    </label>
                  </Form.Floating>
                )}
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    as="select"
                    placeholder="Ingrese la verificacion"
                    value={contact.verified}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("verified", e.target.value)
                    }
                  >
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </Form.Control>
                  <Form.Label
                    htmlFor="floatingVerified"
                    className={styles.label}
                  >
                    Verificación
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <br />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={add ? handleSave : patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>{add ? "Agregando..." : "Actualizando..."}</span>
              </>
            ) : (
              <span>{add ? "Agregar Contacto" : "Guardar Cambios"}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default ContactEdit;
