import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Form, Row, Col, Button } from "react-bootstrap";
import styles from "./Information.module.scss";
import DatosGeneralesEdit from "../Edit/DatosGenerales/DatosGeneralesEdit";
import DireccionEdit from "../Edit/Direccion/DireccionEdit";
import ContactEdit from "../Edit/Contacto/ContactoEdit";

const Information = ({ institution, setInstitution, callback }) => {
  const [contact, setContact] = useState([]);
  const [currentContactIndex, setCurrentContactIndex] = useState(0);
  const [isAddContactMode, setIsAddContactMode] = useState(false);
  const [datosGeneralesEdit, setDatosGeneralesEdit] = useState(false);
  const [directionEdit, setDirectionEdit] = useState(false);
  const [contactEdit, setContactEdit] = useState(false);

  const handleNextContacts = () => {
    if (currentContactIndex < institution.contacts.length - 1) {
      setCurrentContactIndex(currentContactIndex + 1);
    }
  };

  const handlePreviousContacts = () => {
    if (currentContactIndex > 0) {
      setCurrentContactIndex(currentContactIndex - 1);
    }
  };

  const showCurrentContact = () => {
    const currentContact = institution.contacts.find(
      (contact, index) => index == currentContactIndex
    );
    return (
      <Row className={styles.group}>
        <Form.Label className={styles.groupLabel}>Contactos</Form.Label>
        <Row className="mb-3">
          <Col>
            <Form.Label bsPrefix={styles.label}>Número de contacto</Form.Label>
            <Form.Control
              bsPrefix={styles.input}
              value={currentContact.contact_number}
              disabled
            />
          </Col>
          <Col>
            <Form.Label bsPrefix={styles.label}>Tipo de contacto</Form.Label>
            <Form.Control
              bsPrefix={styles.input}
              value={
                currentContact.type === "PRIMARIO" ? "Primario" : "Secundario"
              }
              disabled
            />
          </Col>
        </Row>
      </Row>
    );
  };

  return (
    <>
      <Toaster />
      <DatosGeneralesEdit
        data={institution}
        show={datosGeneralesEdit}
        handleClose={() => setDatosGeneralesEdit(false)}
        callback={() => {
          setDatosGeneralesEdit(false);
          callback();
        }}
      />
      <DireccionEdit
        data={institution}
        show={directionEdit}
        handleClose={() => setDirectionEdit(false)}
        callback={() => {
          setDirectionEdit(false);
          callback();
        }}
      />

      <ContactEdit
        data={institution}
        show={contactEdit}
        add={isAddContactMode}
        handleClose={() => {
          callback();
          setContactEdit(false);
          setIsAddContactMode(false);
        }}
        currentContactsIndex={currentContactIndex}
      />

      <Form>
        <Row className={styles.group} onClick={(e) => { e.preventDefault();setDatosGeneralesEdit(true); }}>
          <Form.Label className={styles.groupLabel}>Datos generales</Form.Label>
          <Row className="mb-2">
            <Col md={8}>
              <Form.Label bsPrefix={styles.label}>
                Nombre de la Institución
              </Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={institution.name}
                disabled
              />
            </Col>
            <Col md={4}>
              <Form.Label bsPrefix={styles.label}>RUC</Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={institution.ruc}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              <Form.Label bsPrefix={styles.label}>Tipo de documento</Form.Label>
              <Form.Control
                bsPrefix={styles.input}
                value={institution.institution_type}
                disabled
              />
            </Col>
          </Row>
        </Row>

        {institution.address != null && (
          <Row className={styles.group} onClick={(e) => { e.preventDefault(); setDirectionEdit(true)}}>
            <Form.Label className={styles.groupLabel}>Dirección</Form.Label>
            <Row className="mb-3">
              <Col>
                <Form.Label bsPrefix={styles.label}>Departamento</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.department}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label}>Ciudad</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.city}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label}>Barrio</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.neighborhood}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Form.Label bsPrefix={styles.label}>Calle principal</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.principalStreet}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label}>
                  Calle secundaria
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.secondaryStreet}
                  disabled
                />
              </Col>
              <Col>
                <Form.Label bsPrefix={styles.label}>
                  Número de residencia
                </Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.houseNumber}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label bsPrefix={styles.label}>Pais</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.country}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label bsPrefix={styles.label}>Descripción</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.description}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Label bsPrefix={styles.label}>Coordenadas</Form.Label>
                <Form.Control
                  bsPrefix={styles.input}
                  value={institution.address.coordinates}
                  disabled
                />
              </Col>
            </Row>
          </Row>
        )}

        <Row className={styles.group}>
          <Row
            onClick={(e) => {
              e.preventDefault();
              setContactEdit(true);
              setIsAddContactMode(false);
            }}
          >
            <Row className="align-items-center">
              <Col>
                <Form.Label className={styles.groupLabel} as={Col}>
                  Contactos{" "}
                  {institution.contacts.length > 0 && (
                    <>
                      {" "}
                      {currentContactIndex + 1} de {institution.contacts.length}
                    </>
                  )}
                </Form.Label>
              </Col>
            </Row>
            {institution.contacts.length > 0 && showCurrentContact()}
          </Row>
          {institution.contacts.length > 1 && (
            <Row style={{ marginTop: "1rem" }}>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handlePreviousContacts}
                  disabled={currentContactIndex === 0}
                >
                  {"<"}
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="secondary"
                  onClick={handleNextContacts}
                  disabled={
                    currentContactIndex === institution.contacts.length - 1
                  }
                >
                  {">"}
                </Button>
              </Col>
            </Row>
          )}
        </Row>
      </Form>
    </>
  );
};

export default Information;
