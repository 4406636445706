import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "../Personas.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../../Keycloak";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DirectionModal from "../PersonModals/DirectionModal/DirectionModal";

const Direcciones = ({
  updateAddresses,
  prevStep,
  nextStep,
  addresses,
  setAddresses,
  setViewPerson
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [city, setCity] = useState(null);
  const [department, setDepartment] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [principalStreet, setPrincipalStreet] = useState("");
  const [secondaryStreet, setSecondaryStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState(null);
  const [coordinates, setCoordinates] = useState("");
  const [addressType, setAddressType] = useState("HOME");
  const [otherDescription, setOtherDescription] = useState("");

  const [showDirection, setShowDirection] = useState(false);
  const [currentDefendant, setCurrentDefendant] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(null);

  //const [addresses, setAddresses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);

  const [address, setAddress] = useState({});

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => {
        setCities(res.status === 200 ? res.data : []);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const removeAddress = (e, address) => {
    e.preventDefault();
    const updatedAddresses = addresses.filter((current) => current !== address);

    setAddresses(updatedAddresses);

    updateAddresses(updatedAddresses);
  };

  const returnValue = (value) => {
    try {
      return JSON.parse(value).name;
    } catch (e) {
      return value;
    }
  };

  const handleChangeNeighborhood = (event, value, reason, details) => {
    event.preventDefault();
    setNeighborhood(typeof value == "object" ? value : { name: value });
  };

  useEffect(() => {
    findDeparments();
    setCity(undefined);
  }, []);
  useEffect(() => {
    if (department != undefined) {
      findCities(department);
      setNeighborhoods([]);
    }
  }, [department]);
  useEffect(() => {
    if (city != undefined) {
      findNeighborhoods(city);
    }
  }, [city]);

  useEffect(() => {}, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  const array = () => {
    if (!validateFields()) {
      return;
    }
    let depName = departments.find((dep) => dep.id == department)?.name || "";
    let cityName = cities.find((ct) => ct.id == city)?.name || "";
    let neighborhoodName =
      typeof neighborhood === "object" ? neighborhood.name : "";
    const newAddress = {
      city: cityName,
      department: depName,
      neighborhood: neighborhoodName,
      description: description,
      country: country,
      principal_street: principalStreet,
      secondary_street: secondaryStreet,
      house_number: houseNumber,
      coordinates: coordinates,
      address_type: addressType === "OTHER" ? otherDescription : addressType,
    };
    const newAddresses = [...addresses];
    newAddresses.push(newAddress);
    setAddresses(newAddresses);
    updateAddresses(newAddresses);
    cleanAll();
  };

  const cleanAll = () => {
    setCity("");
    setDepartment("");
    setNeighborhood("");
    setDescription("");
    setCountry("");
    setPrincipalStreet("");
    setSecondaryStreet("");
    setHouseNumber("");
    setCoordinates("");
    setAddressType("");
    setOtherDescription("");
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const { keycloak } = useKeycloak();

  const [validated, setValidated] = useState(false);

  const validateFields = () => {
    if (!department || !city) {
      toast.error("Debe seleccionar un departamento y una ciudad.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidated(true);
    nextStep();
  };

  const skip = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <>
      <DirectionModal
        data={selectedAddress}
        show={showDirection}
        handleClose={() => setShowDirection(false)}
      />
      <Form validated={validated} onSubmit={handleSubmit}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Row className="mt-2 mb-4">
            <Form.Group as={Col} controlId="departamentValidation">
              <Form.Label className={styles.label}>Departamento</Form.Label>
              <Form.Select
                className={styles.input}
                value={department ?? ""}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value={undefined}>Seleccione</option>
                {departments.map((current) => (
                  <option key={current.id} value={current.id}>
                    {current.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="cityValidation">
              <Form.Label className={styles.label}>Ciudad</Form.Label>
              <Form.Select
                className={styles.input}
                value={city ?? ""}
                onChange={(e) => setCity(e.target.value)}
              >
                <option value={undefined}>Seleccione</option>
                {cities.map((current) => (
                  <option key={current.id} value={current.id}>
                    {current.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col} controlId="neighborhoodValidation">
              <Autocomplete
                options={neighborhoods}
                getOptionLabel={(option) =>
                  typeof option === "object" ? option.name : option
                }
                value={neighborhood || ""}
                className={styles.input}
                freeSolo
                autoSelect
                onChange={handleChangeNeighborhood}
                renderInput={(params) => (
                  <TextField
                    className={styles.label}
                    {...params}
                    label="Barrio / Localidad"
                    variant="standard"
                    placeholder="Seleccione"
                  />
                )}
              />
            </Form.Group>
          </Row>
          <Row>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    placeholder="Ingrese la descripción"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="description">
                    Descripción
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="country"
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value);
                    }}
                    placeholder="Ingrese el país"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="country">
                    País
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="principal_street"
                    value={principalStreet}
                    onChange={(e) => {
                      setPrincipalStreet(e.target.value);
                    }}
                    placeholder="Ingrese la calle principal"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="principal_street">
                    Calle principal
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="secondary_street"
                    value={secondaryStreet}
                    onChange={(e) => {
                      setSecondaryStreet(e.target.value);
                    }}
                    placeholder="Ingrese la calle secundaria"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="secondary_street">
                    Calle secundaria
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="house_number"
                    value={houseNumber}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue)) {
                        setHouseNumber(inputValue);
                      }
                    }}
                    placeholder="Ingrese el número de casa"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="house_number">
                    Número de casa
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    name="coordinates"
                    value={coordinates}
                    onChange={(e) => {
                      setCoordinates(e.target.value);
                    }}
                    placeholder="Ingrese las coordenadas"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="coordinates">
                    Coordenadas
                  </label>
                </Form.Floating>
              </Form.Group>
            </Row>
            <Row className={`mt-2 mb-4 ${styles.flexRow}`}>
              <Col md={4}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      name="address_type"
                      className={styles.input}
                      as="select"
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      required
                    >
                      <option value="HOME">Domicilio</option>
                      <option value="LABORAL">Laboral</option>
                      <option value="OTHER">Otro</option>
                    </Form.Control>
                    <label htmlFor="address_type" className={styles.label}>
                      Tipo de dirección
                    </label>
                  </Form.Floating>
                </Form.Group>
              </Col>
              {addressType === "OTHER" && (
                <Col md={4}>
                  <Form.Group as={Col} className={`${styles.flexCol}`}>
                    <Form.Floating className="mt-3">
                      <Form.Control
                        name="other_address_description"
                        className={styles.input}
                        type="text"
                        placeholder="Ingrese la descripción"
                        value={otherDescription}
                        onChange={(e) => setOtherDescription(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="other_address_description"
                        className={styles.label}
                      >
                        Especifique
                      </label>
                    </Form.Floating>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Row>

          {addresses && addresses.length > 0 ? (
            <Table className={styles.table} style={{ marginTop: "1rem" }}>
              <thead>
                <th>Departamento</th>
                <th>Ciudad</th>
                <th>Barrio</th>
                <th>Calle Principal</th>
                <th>Calle Secundaria</th>
                <th>Número de casa</th>
                <th>Acciones</th>
              </thead>
              <tbody>
                {addresses.map((address) => (
                  <tr>
                    <td>{address.department}</td>
                    <td>{address.city}</td>
                    <td>{address.neighborhood}</td>
                    <td>{address.principal_street}</td>
                    <td>{address.secondary_street}</td>
                    <td>{address.house_number}</td>
                    <td>
                      <AiOutlineFileSearch
                        onClick={(e) => {
                          e.preventDefault();

                          setSelectedAddress(address);
                          setShowDirection(true);
                        }}
                        title="Ver"
                        className={styles.icon}
                      />
                      &nbsp;
                      <FiTrash
                        onClick={(e) => removeAddress(e, address)}
                        title="Descartar"
                        className={styles.icon}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}

          <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
            <Button
              bsPrefix={styles.next}
              onClick={() => {
                array();
              }}
              className="mt-3"
            >
              GUARDAR DIRECCION
            </Button>
          </Col>

          <Row
            className="mt-4"
            style={{ display: "flex", paddingTop: ".7rem" }}
          >
            <Col md={6} style={{ marginRight: "auto", textAlign: "left" }}>
              <Button bsPrefix={styles.next} onClick={() => { setViewPerson(true); prevStep() }}>
                ANTERIOR
              </Button>
            </Col>

            {addresses.length > 0 ? (
              <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button bsPrefix={styles.next} type="submit">
                  SIGUIENTE
                </Button>
              </Col>
            ) : (
              <Col md={6} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button bsPrefix={styles.next} onClick={skip}>
                  OMITIR
                </Button>
              </Col>
            )}
          </Row>
        </Row>
      </Form>
    </>
  );
};

export default Direcciones;
