import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "./DatosGeneralesEdit.module.scss";

const DatosGeneralesEdit = ({ show, handleClose, callback, data }) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(undefined);
  const [ruc, setRuc] = useState(undefined);
  const [institution_type, setInstitutionType] = useState(undefined);

  const { keycloak } = useKeycloak();

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
        "ngrok-skip-browser-warning": "true",
      },
    };
  };

  useEffect(() => {
    if (data) {
      setName(data.name);
      setRuc(data.ruc);
      setInstitutionType(data.institution_type);
    }
  }, [data]);

  const buildBody = () => {
    return {
      name,
      ruc,
      institution_type,
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/institutions/${data.institution_id}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        callback();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err.data
            ? ": " + err.data.message
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  return data ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Datos Generales</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mt-2 mb-4">
              <Form.Group className="mb-3" as={Col}>
                <Form.Floating>
                  <Form.Control
                    id="floatingFirstName"
                    required={!preCarga}
                    type="text"
                    value={name}
                    className={styles.input}
                    placeholder="Ingrese el nombre"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <Form.Label
                    htmlFor="floatingFirstName"
                    className={styles.label}
                  >
                    Nombre de la Institucion
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    as="select"
                    id="floatingLastName"
                    required={!preCarga}
                    value={institution_type}
                    className={styles.input}
                    onChange={(e) => {
                      setInstitutionType(e.target.value);
                    }}
                  >
                    <option value="" selected disabled>
                      Seleccione...
                    </option>
                    <option value="PUBLIC">Público</option>
                    <option value="PRIVATE">Privado</option>
                  </Form.Control>
                  <Form.Label
                    htmlFor="floatingLastName"
                    className={styles.label}
                  >
                    Tipo de Institucion
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="floatingDocumentNumber"
                      required={!preCarga}
                      value={ruc}
                      onChange={(e) => setRuc(e.target.value)}
                      className={styles.input}
                      placeholder="Ingrese el numero de documento"
                      type="text"
                      disabled
                    />
                    <Form.Label
                      htmlFor="floatingDocumentNumber"
                      className={styles.label}
                    >
                      Número de RUC
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>Actualizando...</span>
              </>
            ) : (
              <span>Guardar</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DatosGeneralesEdit;
