import React, { useState, useEffect } from "react";
import styles from "./UpdateActionDateModal.module.css";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";
const UpdateActionDateModal = ({ show, data, handleClose }) => {

    const [ loading, setLoading ] = useState(false);
    const [actionDate, setActionDate] = useState(undefined);

    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }      
    useEffect(() => {
        setActionDate(data.actionDate);
    }, [data])

    const body = () => {
        return {
            "cause_history_id": data.causeHistoryId,
            "cause_id": data.causeId,
            "new_date_action": actionDate
        }
    }

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true); 
              
        axios.patch(`${process.env.REACT_APP_API_URL}/causes/history`, body(), prepareHeaders()).
        then(res => {
            console.log(res);
            setLoading(false);
            handleClose();
        }).
        catch(err => {
            console.log(err);
            setLoading(false);
            handleClose();            
        })
    }


    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><h5>Fecha de la acción</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>     
                        <Row className="mb-3">
                            
                        <Form.Group >
                            <Form.Control
                            value={actionDate}
                            onChange={(e) => { setActionDate(e.target.value); }}   
                            type="date"/>
                            
                        </Form.Group>
                        </Row>        
                                              
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary" onClick={handleRegister} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Guardando...</span>
                                </>
                            )
                            :
                            <span>Guardar</span>
                        }
                    </Button>                    
                </Modal.Footer>
            </Modal>            
        </>
    );

}

export default UpdateActionDateModal;