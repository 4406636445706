import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "./Depositos.module.css";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { checkRoles } from "../../Keycloak";

const Depositos = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");
  const [storageHandler, seStorageHandler] = useState("");
  const [location, setLocation] = useState("");

  const { keycloak } = useKeycloak();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  {
    /*const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const newErrores = {
      name: "",
      storageHandler: "",
    };
    if (!name) {
      newErrores.name = "Este campo es obligatorio";
    }
    if (!storageHandler) {
      newErrores.storageHandler = "Este campo es obligatorio";
    }

    if (newErrores.name || newErrores.storageHandler) {
      return;
    }
    const dateToSend = getFormattedDate();

    const person = {
      name: name,
      storage_handler: storageHandler,
      birth_date: dateToSend,
      location,
    };

    setValidated(true);
    updatePerson(person);
    nextStep();
  };*/
  }

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const buildBody = () => {
    return {
      name: name,
      storage_handler: storageHandler,
      location: location,
    };
  };

  const handleSave = () => {
    const URL = `https://39bd-181-94-245-59.ngrok-free.app/udea.deputy-prosecutor/api/evidence/storage`;
    axios
      .post(URL, buildBody, prepareHeaders())
      .then((res) => {
        toast.success("Depósito registrado exitosamente", {
          position: "top-center",
        });
        console.log(res);
        navigate(`/depositos`);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar el depósito", {
          position: "top-center",
        });
        console.log(err);
        console.log(buildBody);
      });
  };

  return (
    <>
      <Form validated={validated}>
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <div className={styles.container}>
            <h2>Depósitos fiscales</h2>
            <Row className="mb-4">
              <Form.Label className={styles.groupLabel}>
                Registrar depósito
              </Form.Label>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="name"
                    type="text"
                    value={name || ""}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder="Ingrese el nombre de la persona"
                    className={styles.input}
                  />
                  <label className={styles.label} htmlFor="name">
                    Nombre
                  </label>
                </Form.Floating>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    id="storage_handler"
                    value={storageHandler || ""}
                    onChange={(e) => {
                      seStorageHandler(e.target.value);
                    }}
                    className={styles.input}
                    type="text"
                    placeholder="Ingrese el apellido de la persona"
                  />
                  <label className={styles.label} htmlFor="storage_handler">
                    Encargado
                  </label>
                </Form.Floating>
              </Form.Group>
              <Row className="mt-2 mb-4">
                <Col md={6}>
                  <Form.Group as={Col}>
                    <Form.Floating>
                      <Form.Control
                        id="location"
                        type="text"
                        value={location}
                        onChange={(e) => {
                          setLocation(e.target.value);
                        }}
                        placeholder="Ingrese el location de la persona"
                        className={styles.input}
                      />
                      <label className={styles.label} htmlFor="location">
                        Ubicación
                      </label>
                    </Form.Floating>
                  </Form.Group>
                </Col>
              </Row>
            </Row>

            <Row>
              <Col md={12} style={{ marginLeft: "auto", textAlign: "right" }}>
                <Button
                  bsPrefix={styles.next}
                  onClick={() => {
                    handleSave();
                  }}
                  className="mt-3"
                >
                  GUARDAR DEPOSITO
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
      </Form>
    </>
  );
};

export default Depositos;
