import { useState, useEffect } from 'react';
import axios from "axios";
import { Form, Button, Col, Row, Table, InputGroup, DropdownButton, Dropdown, Badge } from 'react-bootstrap';
import { RiCloseFill, RiSortAsc, RiSortDesc, RiTableFill } from 'react-icons/ri';
import { AiOutlineSearch, AiOutlineClear } from 'react-icons/ai';
import { FaFileCirclePlus } from "react-icons/fa6";
import { Toaster } from 'react-hot-toast';
import styles from "../../causas/Causas/Causas.module.css";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from "react-router-dom";

const ConsultarInstituciones = () => {
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

    const [view, setView] = useState('T');
    const [institutions, setInstitutions] = useState(undefined);
    const [institution, setInstitution] = useState(undefined);

    // Parametros tabla
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [sorting, setSorting] = useState([]);
    const [totalPages, setTotalPages] = useState(10);
    const [totalElements, setTotalElements] = useState(0);

    const [institutionType, setInstitutionType] = useState('');
    const [institutionName, setInstitutionName] = useState('');
    const [institutionRuc, setInstitutionRuc] = useState('');

    // Para traer los datos
    const prepareHeaders = () => {
      return {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${keycloak.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      };
    };

    // Consulta a la API
    const getInstitutions = () => {
      setLoading(true);
      let params = buildParams();
      const URL = `${process.env.REACT_APP_API_URL}/institutions?page=${page}&pageSize=${pageSize}${params}`;
      axios
      .get(URL, prepareHeaders())
      .then((res) => {
        setInstitutions(res.status === 206 ? res.data.content : []);
        setTotalPages(res.status === 206 ? res.data.totalPages : 10);
        setTotalElements(res.status === 206 ? res.data.totalElements : 0);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setInstitutions([]);
        setLoading(false);
      });
    }

    useEffect(() => { getInstitutions(); }, []);

    const buildParams = () => {
      let query = "";
      if (institutionType) {
        query += `&institution_type=${institutionType}`;
      }
      if (institutionRuc) {
        query += `&ruc=${institutionRuc}`;
      }
      if (institutionName) {
        query += `&name=${institutionName}`
      }
      if (sorting && sorting.length > 0) {
        sorting.map((sort) => {
          query += `&sortBy=${sort.key}:${sort.direction}`;
        });
      }
      console.log(query);
      return query;
    };

    // Funciones para filtrar y ordenar la tabla
    const sortingBy = (field, label) => {
      if (sorting.filter((element) => element.key === field).length > 0) {
        setSorting(sorting.filter((element) => element.key !== field));
      } else {
        setSorting([...sorting, { key: field, label, direction: "ASC" }]);
      }
    };

    const changeDirection = (sort) => {
      let elements = [];
      for (let current of sorting) {
        if (current.key === sort.key) {
          current.direction = sort.direction === 'ASC' ? 'DESC' : 'ASC';
        }
        elements.push(current);
      }
      setSorting(elements)
    }

    // Limpia los datos
    const reset = (e) => {
      e.preventDefault();
      setPage(1);
      setInstitutionName("");
      setInstitutionRuc("");
      setInstitutionType("");
      setSorting([]);
    }

    return (
      <>
        <Toaster />
        <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <Col md={3}>
            <div className={styles.sideBar}>
              <Form>
              <Row style={{ marginBottom: ".5rem" }}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      id="institution-type"
                      as="select"
                      value={institutionType}
                      className={styles.inputFloating}
                      onChange={(e) => setInstitutionType(e.target.value) }
                      placeholder="Seleccione el tipo de institución"
                    >
                      <option value="">Seleccione tipo de institución</option>
                      <option value="PUBLIC">Público</option>
                      <option value="PRIVATE">Privado</option>
                    </Form.Control>
                    <label htmlFor="institution-type" className={styles.label}>Tipo de Institución</label>
                  </Form.Floating>
                </Form.Group>
              </Row>
                <Row style={{ marginBottom: ".5rem" }}>
                  <Form.Group as={Col}>
                    <Form.Floating>
                      <Form.Control
                        id="institution-ruc"
                        type="text"
                        value={institutionRuc}
                        className={styles.inputFloating}
                        placeholder="Ingrese el número de RUC"
                        onChange={(e) =>  setInstitutionRuc(e.target.value) }
                      />
                      <label htmlFor="institution-ruc" className={styles.label}>Número de RUC</label>
                    </Form.Floating>
                  </Form.Group>
                </Row>

                <Row style={{ marginBottom: ".5rem" }}>
                  <Form.Group as={Col}>
                    <Form.Floating>
                      <Form.Control
                        id="institution-name"
                        type="text"
                        placeholder="Nombre de la Institución"
                        value={institutionName}
                        className={styles.inputFloating}
                        onChange={(e) => setInstitutionName(e.target.value)}
                      />
                      <label htmlFor="institution-name" className={styles.label}>Nombre de la Institución</label>
                    </Form.Floating>
                  </Form.Group>
                </Row>

                <Row style={{ marginBottom: "1rem" }}>
                  <Col
                    style={{
                      textAlign: "right",
                      marginTop: "auto",
                      display: "flex",
                    }}
                  >
                    <DropdownButton bsPrefix={styles.secondary} title="Ordenar" style={{ marginLeft: "auto" }} >
                        <Dropdown.Item className={styles.item} onClick={(e) => sortingBy("name", "Nombre")}>Nombre</Dropdown.Item>
                        <Dropdown.Item className={styles.item} onClick={(e) => sortingBy("ruc", "N° RUC")}>N° RUC</Dropdown.Item>
                    </DropdownButton>
                    &nbsp;&nbsp;
                    <Button bsPrefix={styles.primary} onClick={reset}> 
                        Limpiar <AiOutlineClear />
                    </Button>
                    &nbsp;&nbsp;
                    <Button bsPrefix={styles.primary} onClick={() => getInstitutions()}>
                      Buscar <AiOutlineSearch />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col md={9}>
            <div className={styles.container}>
              <h2>Instituciones</h2>
              <Row>
                <Col as={Col}>
                  <Button
                    onClick={(e) => {e.preventDefault(); navigate("/institucion/crear");}} 
                    bsPrefix={styles.newPrimary} 
                    style={{ marginLeft: "auto" }} 
                  >
                    &nbsp;&nbsp;Nueva institución &nbsp;&nbsp;<FaFileCirclePlus />&nbsp;&nbsp;
                  </Button>
                </Col>
              </Row>

              {loading ? (
                <span className="mt-3 mb-2">Buscando...</span>
              ) : !loading && (!institutions || institutions.length === 0) ? (
                <span className="mt-3 mb-2">No hay resultados</span>
              ) : (
                <>
                  <Row style={{ flexDirection: "row-reverse" }}>
                    <Col md="auto" style={{ textAlign: "right" }}>
                      <InputGroup
                        style={{
                          flexWrap: "wrap",
                          alignItems: "stretch",
                          justifyContent: "flex-end",
                        }}
                      >
                        <RiTableFill
                          style={{ marginTop: "auto" }}
                          className={ view === 'T' ? styles.iconActive : styles.iconInactive }
                          onClick={(e) => {
                            e.preventDefault();
                            setView('T');
                            setPageSize(20);
                            setPage(1);
                          }}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="auto" style={{ textAlign: "right" }}>
                      { sorting.map((sort) => (
                        <>
                          <Badge className={styles.sortItem}>
                            {sort.label}
                            &nbsp;
                            {sort.direction === "ASC" ? (
                              <RiSortAsc
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  changeDirection(sort);
                                }}
                              />
                            ) : (
                              <RiSortDesc
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  changeDirection(sort);
                                }}
                              />
                            )}
                            &nbsp;
                            <RiCloseFill
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                sortingBy(sort.key);
                              }}
                            />
                          </Badge>
                          &nbsp;
                        </>
                      ))}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem" }}>
                    <Col md={6}>
                      {<CustomPagination
                        page={page}
                        pageSize={pageSize}
                        changePageSize={setPageSize}
                        toPage={(page) => { setPage(page); }}
                        totalPages={totalPages}
                      /> }
                    </Col>
                    <Col
                      md={6}
                      style={{ textAlign: "right", marginTop: "auto" }}
                    >
                      <span>
                        {totalElements}{" "}
                        {totalElements > 1 ? "Instituciones" : "Institución"}
                      </span>
                    </Col>
                  </Row>
                  {view === 'T' ? (
                    <Table className={styles.table}>
                      <thead>
                        <th>Nombre</th>
                        <th>N° RUC</th>
                        <th>Tipo</th>
                        <th></th>
                      </thead>
                      <tbody>
                        {institutions.map((institution) => (
                          <tr>
                            <td>{institution.name}</td>
                            <td>{institution.ruc}</td>
                            <td>{institution.institution_type === "PUBLIC" ? "Público" : "Privado"}</td>
                            <td>
                              <div style={{ display: "flex" }}>
                                <Button bsPrefix={styles.next}
                                   onClick={(e) => {
                                     navigate(`/institucion/${institution.institution_id}`);
                                   }}
                                >
                                  VER
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <> </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
}

export default ConsultarInstituciones;