// ParentComponent.jsx
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import {
  Form,
  Button,
  Col,
  Row,
  Alert,
  DropdownButton,
  Dropdown,
  Badge,
  Table,
  InputGroup,
} from "react-bootstrap";
import {
  RiSortAsc,
  RiSortDesc,
  RiCloseFill,
  RiTableFill,
  RiArticleFill,
} from "react-icons/ri";
import styles from "./Personas.module.css";
import { useKeycloak } from "@react-keycloak/web";
import Stepper from "../../components/Stepper";
import Step from "../../components/Stepper/Step";
import { useSearchParams } from "react-router-dom";
import { checkRoles } from "../../Keycloak";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Personas from "./Persona/Personas";
import Direcciones from "./Direccion/Direcciones";
import Contactos from "./Contacto/Contactos";
import Trabajos from "./Trabajo/trabajos";
import toast, { Toaster } from "react-hot-toast";

const PersonaGeneral = () => {
  const [person, setPerson] = useState({
    document_type: "CI",
    document_number: null,
    first_name: "",
    last_name: "",
    birth_date: null,
    gender: "",
    marital_status: "",
    profession: "",
    alias: "",

    addresses: [],
    contacts: [],
    person_institutions: [],
  });

  const [addresses, setAddresses] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [viewPerson, setViewPerson] = useState(false);

  const { keycloak } = useKeycloak();

  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const createSteps = () => {
    return [
      {
        label: "Datos generales",
        code: "1",
        id: 1,
      },
      {
        label: "Dirección",
        code: "2",
        id: 2,
      },
      {
        label: "Contacto",
        code: "3",
        id: 3,
      },
      {
        label: "Institución laboral",
        code: "4",
        id: 4,
      },
    ];
  };

  const steps = createSteps();

  const updatePerson = (fieldOrObject, value) => {
  setPerson((prevPerson) => {
    if (typeof fieldOrObject === "object" && fieldOrObject !== null) {
      return {
        ...prevPerson,
        ...fieldOrObject,
      };
    }

    return {
      ...prevPerson,
      [fieldOrObject]: value,
    };
  });
};

  const updateAddresses = (addresses) => {
    setPerson((prevData) => ({
      ...prevData,
      addresses: addresses,
    }));
  };

  const updateContacts = (contacts) => {
    setPerson((prevData) => ({
      ...prevData,
      contacts: contacts,
    }));
  };

  const updateJobs = (person_institutions) => {
    setPerson((prevData) => ({
      ...prevData,
      person_institutions: person_institutions,
    }));
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSave = () => {
    const URL = `${process.env.REACT_APP_API_URL}/person`;
    axios
      .post(URL, person, prepareHeaders())
      .then((res) => {
        toast.success("Persona registrada exitosamente", {
          position: "top-center",
        });
        navigate(`/personas`);
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la persona ", {
          position: "top-center",
        });
        console.log(err);
      });
      setViewPerson(false);
  };

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  return (
    <>
      <Toaster />
      <Row style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <div className={styles.container}>
        <Stepper steps={steps} current={currentStep}>
          <Step step={1}>
            <Personas
              person={person}
              updatePerson={updatePerson}
              nextStep={handleNextStep}
              setViewPerson = {setViewPerson}
              viewPerson = {viewPerson}
            />
          </Step>
          <Step step={2}>
            <Direcciones
              updateAddresses={updateAddresses}
              prevStep={handlePrevStep}
              nextStep={handleNextStep}
              addresses={addresses}
              setAddresses={setAddresses}
              setViewPerson = {setViewPerson}
            />
          </Step>
          <Step step={3}>
            <Contactos
              updateContacts={updateContacts}
              prevStep={handlePrevStep}
              nextStep={handleNextStep}
              contacts={contacts}
              setContacts={setContacts}
            />
          </Step>
          <Step step={4}>
            <Trabajos
              updateJobs={updateJobs}
              prevStep={handlePrevStep}
              handleSave={handleSave}
              jobs={jobs}
              setJobs={setJobs}
            />
          </Step>
        </Stepper>
      </div>
      </Row>
    </>
  );
};

export default PersonaGeneral;
