import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { checkRoles } from "../../../../../Keycloak";
import { SlOptions } from "react-icons/sl";
import { AiOutlineDeliveredProcedure } from "react-icons/ai";
import { RiMergeCellsHorizontal } from "react-icons/ri";
import { TiCancel } from "react-icons/ti";
import { FiShieldOff } from "react-icons/fi";
import { MdDateRange } from "react-icons/md";
import { CauseStatus } from "../../../../../constants/CauseStatus";
import { Dropdown } from "react-bootstrap";
import axios from "axios";

const Options = ({ cause, style, item, callback, setShowEditDate, setShowAssign, setShowArchive, setShowImputation, setShowAccusation, setShowOpportunityCriteria, setShowOnConditionalSuspension, setShowConciliateStatus, setShowReopen, setShowReject, setShowAbbreviateProcess, setShowDisassociate }) => {

    const { keycloak } = useKeycloak();    

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const cancel = (e)  => {
        e.preventDefault();
        axios.delete(`${process.env.REACT_APP_API_URL}/causes/${cause.id}`, prepareHeaders())
        .then(res => {
            callback();
        })
        .catch(err => {
            console.log(err);
        });          
    }

    const editDate = (e) => {
        e.preventDefault();
        setShowEditDate(true);
    }    

    const resolveActions = ( cause ) => {
        if(checkRoles(keycloak) == "fiscal-adjunto"){
            switch(cause.status){
                case CauseStatus.INVESTIGATION_PROCESS.code:
                    return (
                        <>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowAssign(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Asignar manualmente el fiscal
                            </Dropdown.Item>
                            <Dropdown.Item className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Volver a sortear el fiscal
                            </Dropdown.Item>                    
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowArchive(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Archivar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowReject(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Desestimar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowImputation(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Imputar
                            </Dropdown.Item>                            
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowOpportunityCriteria(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Criterio de oportunidad
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowOnConditionalSuspension(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                En suspensión condicional
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowAbbreviateProcess(true); }} className={item}>
                                <RiMergeCellsHorizontal />&nbsp;
                                Procedimiento abreviado
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowConciliateStatus(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Conciliada
                            </Dropdown.Item>
                            <Dropdown.Item onClick={editDate} className={item}>
                                <MdDateRange />&nbsp;
                                Editar fecha
                            </Dropdown.Item>
                            <Dropdown.Item  onClick={cancel} className={item}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );
                    case CauseStatus.REJECTED.code:
                    case CauseStatus.ARCHIVED.code:
                        return (
                            <>
                                <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowReopen(true); }} className={item}>
                                    <FiShieldOff />&nbsp;
                                    Reabrir
                                </Dropdown.Item>                        
                                <Dropdown.Item onClick={editDate} className={item}>
                                    <MdDateRange />&nbsp;
                                    Editar fecha
                                </Dropdown.Item>
                                <Dropdown.Item onClick={cancel} className={item}>
                                    <TiCancel />&nbsp;
                                    Cancelar
                                </Dropdown.Item>
                            </>                        
                        );
                default:
                    return (
                        <>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowAssign(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Asignar manualmente el fiscal
                            </Dropdown.Item>
                            <Dropdown.Item onClick={editDate} className={item}>
                                <MdDateRange />&nbsp;
                                Editar fecha
                            </Dropdown.Item>
                            <Dropdown.Item onClick={cancel} className={item}>
                                <TiCancel />&nbsp;
                                Cancelar
                            </Dropdown.Item>
                        </>
                    );
            }
        }
        else if(checkRoles(keycloak) == "fiscal"){
            switch(cause.status){
                case CauseStatus.INVESTIGATION_PROCESS.code:
                    return (
                        <>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowArchive(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Archivar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowReject(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Desestimar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowImputation(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Imputar
                            </Dropdown.Item>                            
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowOpportunityCriteria(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Criterio de oportunidad
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowOnConditionalSuspension(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                En suspensión condicional
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowAbbreviateProcess(true); }} className={item}>
                                <RiMergeCellsHorizontal />&nbsp;
                                Procedimiento abreviado
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowConciliateStatus(true); }} className={item}>
                                <AiOutlineDeliveredProcedure />&nbsp;
                                Conciliada
                            </Dropdown.Item>
                            <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowDisassociate(true); }}className={item}>
                                <TiCancel />&nbsp;
                                Inhibirse
                            </Dropdown.Item>
                        </>
                    );
                    case CauseStatus.REJECTED.code:
                    case CauseStatus.ARCHIVED.code:
                        return (
                            <>
                                <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowReopen(true); }} className={item}>
                                    <FiShieldOff />&nbsp;
                                    Reabrir
                                </Dropdown.Item>
                            </>                        
                        );
                    case CauseStatus.IMPUTED.code:
                        return (
                            <>
                                <Dropdown.Item onClick={(e) => { e.preventDefault(); setShowAccusation(true); }} className={item}>
                                    <FiShieldOff />&nbsp;
                                     Acusar
                                </Dropdown.Item>
                            </>                        
                        );    
                default:
                    return (
                        <></>
                    );
            }            
        }
        else {
            return (
                <>
                    <Dropdown.Item onClick={editDate} className={item}>
                        <MdDateRange />&nbsp;
                        Editar fecha
                    </Dropdown.Item>
                    <Dropdown.Item onClick={cancel} className={item}>
                        <TiCancel />&nbsp;
                        Cancelar
                    </Dropdown.Item>              
                </>
            );            
        }
    }    

    const haveOptions = ( cause ) => {
        return true;
    }        

    return haveOptions(cause) ? (
        <Dropdown align="end">
            <Dropdown.Toggle className={style} id="dropdown-button-dark-example1" variant="secondary">
                <SlOptions />
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
                { resolveActions(cause) }
            </Dropdown.Menu>
        </Dropdown>                                                    
    )
    : <></>;

}

export default Options;