import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Modal, Spinner } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import toast, { Toaster } from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import styles from "../../../Personas.module.css";

const DirectionEdit = ({
  show,
  handleClose,
  callback,
  data,
  currentAddressIndex,
  add,
}) => {
  const [preCarga, setPreCarga] = useState(true);
  const [loading, setLoading] = useState(false);

  const [department, setDepartment] = useState(null);
  const [city, setCity] = useState(null);
  const [neighborhood, setNeighborhood] = useState(undefined);
  const [otherDescription, setOtherDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [principalStreet, setPrincipalStreet] = useState(undefined);
  const [secondaryStreet, setSecondaryStreet] = useState(undefined);
  const [houseNumber, setHouseNumber] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  //const [currentAddress, setCurrentAddress] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [neighborhoods, setNeighborhoods] = useState([]);
  const [cities, setCities] = useState([]);

  const { keycloak } = useKeycloak();

  const [address, setAddress] = useState({});

  const prepareHeaders = () => {
    return {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${keycloak.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const findDeparments = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "/departments", prepareHeaders())
      .then((res) => setDepartments(res.status == 200 ? res.data : []))
      .catch((error) => {
        console.error(error);
      });
  };

  const findCities = (departmentId) => {
    return axios
      .get(
        process.env.REACT_APP_API_URL + `/cities?department_id=${departmentId}`,
        prepareHeaders()
      )
      .then((res) => {
        setCities(res.status === 200 ? res.data : []);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const findNeighborhoods = (cityId) => {
    axios
      .get(
        process.env.REACT_APP_API_URL + `/neighborhoods?city_id=${cityId}`,
        prepareHeaders()
      )
      .then((res) => setNeighborhoods(res.status == 200 ? res.data : []))
      .catch((error) => console.error(error));
  };

  const handleChangeNeighborhood = (event, value, reason, details) => {
    event.preventDefault();
    setNeighborhood(typeof value == "object" ? value : { name: value });
  };

  useEffect(() => {
    findDeparments();
    setCity(undefined);
  }, []);
  useEffect(() => {
    if (department != undefined) {
      findCities(department);
      setNeighborhoods([]);
    }
  }, [department]);
  useEffect(() => {
    if (city != undefined) {
      findNeighborhoods(city);
    }
  }, [city]);

  useEffect(() => {
    if (!add) {
      console.log("Los departametos son:", departments);
      if (
        data &&
        Array.isArray(data.addresses) &&
        currentAddressIndex !== undefined &&
        departments.length > 0
      ) {
        const currentAddress = data.addresses[currentAddressIndex] || {};

        const departmentId = departments.find(
          (dep) => dep.name === currentAddress.department
        )?.id;
        setDepartment(departmentId !== undefined ? departmentId : 0);

        if (departmentId >= 0) {
          findCities(departmentId).then((citiesData) => {
            const cityId = citiesData.find(
              (ct) => ct.name === currentAddress.city
            )?.id;
            setCity(cityId !== undefined ? cityId : 0);
          });
          console.log("El id es:", departmentId);
        } else {
          console.log("El id es por error:", departmentId);
        }

        console.log(departmentId);
        console.log(currentAddress.department);
        console.log(currentAddress.city);

        setNeighborhood(currentAddress.neighborhood || "");

        console.log("Departments loaded:", departments);
        console.log("Cities loaded:", cities);
        console.log("Current address:", data?.addresses[currentAddressIndex]);

        setAddress({
          department: currentAddress.department,
          city: currentAddress.city,
          neighborhood: currentAddress.neighborhood,
          principalStreet: currentAddress.principal_street,
          secondaryStreet: currentAddress.secondary_street,
          houseNumber: currentAddress.house_number,
          description: currentAddress.description,
          country: currentAddress.country,
          coordinates: currentAddress.coordinates,
          addressType: currentAddress.address_type,
        });
        setOtherDescription("");

        const currentAddressType = currentAddress.address_type || "";
        setEditMode(
          currentAddressType === "" ||
            currentAddressType === "HOME" ||
            currentAddressType === "LABORAL"
        );
      }
    } else {
      setAddress({
        department: "",
        city: "",
        neighborhood: "",
        principalStreet: "",
        secondaryStreet: "",
        houseNumber: "",
        description: "",
        country: "",
        coordinates: "",
        addressType: "",
      });
      setOtherDescription("");
      setDepartment("");
      setCity("");
      setNeighborhood("");
    }
  }, [departments, data, currentAddressIndex, add]);

  const handleInputChange = (field, value) => {
    setAddress((prev) => ({ ...prev, [field]: value }));
  };

  const buildBody = () => {
    let depName = departments.find((dep) => dep.id == department)?.name || "";

    let cityName = cities.find((ct) => ct.id == city)?.name || "";

    let neighborhoodName =
      typeof neighborhood === "object" ? neighborhood.name : neighborhood;

    return {
      city: cityName,
      department: depName,
      neighborhood: neighborhoodName,
      description: address.description,
      country: address.country,
      principal_street: address.principalStreet,
      secondary_street: address.secondaryStreet,
      house_number: address.houseNumber,
      coordinates: address.coordinates,
      address_type:
        address.addressType === "OTHER"
          ? otherDescription
          : address.addressType,
    };
  };

  const patch = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/person/${data.personId}/address/${data.addresses[currentAddressIndex].addressId}`,
        buildBody(),
        prepareHeaders()
      )
      .then((res) => {
        toast.success("Datos actualizados", { position: "top-center" });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        toast.error(
          "Ha ocurrido un error al actualizar los datos " + err
            ? ": " + err
            : "",
          { position: "top-center" }
        );
        setLoading(false);
      });
  };

  const handleSave = () => {
    if (!city || !department) {
      toast.error(
        "Por favor seleccione la ciudad y el departamento antes de agregar."
      );
      return;
    }
    const URL = `${process.env.REACT_APP_API_URL}/person/${data.personId}/address`;
    axios
      .post(URL, buildBody(), prepareHeaders())
      .then((res) => {
        toast.success("Direccion registrada exitosamente", {
          position: "top-center",
        });
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        toast.error("Ha ocurrido un error al registrar la direccion ", {
          position: "top-center",
        });
        setLoading(false);
        handleClose();
        console.log(err);
      });
  };

  return (data.addresses[currentAddressIndex] !== undefined &&
    address !== undefined) ||
    add == true ? (
    <>
      <Toaster />
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {add ? "Agregar Dirección" : "Editar Dirección"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h6>Dirección {currentAddressIndex + 1}</h6>
            <Row className="mt-2 mb-4">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Select
                    className={styles.input}
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option value={null}>Seleccione</option>
                    {departments.map((current) => (
                      <option key={current.id} value={current.id}>
                        {current.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label className={styles.label}>Departamento</Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Select
                    className={styles.input}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option value={null}>Seleccione</option>
                    {cities.map((current) => (
                      <option key={current.id} value={current.id}>
                        {current.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Label className={styles.label}>Ciudad</Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Autocomplete
                  options={neighborhoods}
                  getOptionLabel={(option) =>
                    typeof option === "object" ? option.name : option
                  }
                  value={address.neighborhood}
                  className={styles.input}
                  freeSolo
                  autoSelect
                  onChange={handleChangeNeighborhood}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Barrio / Localidad"
                      variant="standard"
                    />
                  )}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    required
                    type="text"
                    value={address.principalStreet}
                    className={styles.input}
                    placeholder="Ingrese la calle principal"
                    onChange={(e) =>
                      handleInputChange("principalStreet", e.target.value)
                    }
                  />
                  <Form.Label className={styles.label}>
                    Calle principal
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese la calle secundaria"
                    value={address.secondaryStreet}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("secondaryStreet", e.target.value)
                    }
                  />
                  <Form.Label className={styles.label}>
                    Calle secundaria
                  </Form.Label>
                </Form.Floating>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Floating>
                  <Form.Control
                    type="text"
                    placeholder="Ingrese el número de residencia"
                    value={address.houseNumber}
                    className={styles.input}
                    onChange={(e) =>
                      handleInputChange("houseNumber", e.target.value)
                    }
                  />
                  <Form.Label className={styles.label}>
                    Número de residencia
                  </Form.Label>
                </Form.Floating>
              </Form.Group>
            </Row>

            <Row className={`mt-2 mb-4 ${styles.flexRow}`}>
              <Col md={4}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    <Form.Control
                      required
                      type="text"
                      value={address.description}
                      className={styles.input}
                      placeholder="Ingrese la descripción del lugar"
                      onChange={(e) =>
                        handleInputChange("description", e.target.value)
                      }
                    />
                    <Form.Label className={styles.label}>
                      Descripción
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group as={Col}>
                  <Form.Floating>
                    {editMode ? (
                      <Form.Control
                        as="select"
                        value={address.addressType}
                        className={styles.input}
                        placeholder="seleccione el tipo"
                        onChange={(e) =>
                          handleInputChange("addressType", e.target.value)
                        }
                        required
                      >
                        <option value="">Seleccione</option>
                        <option value="HOME">Domicilio</option>
                        <option value="LABORAL">Laboral</option>
                        <option value="OTHER">Otro</option>
                      </Form.Control>
                    ) : (
                      <Form.Control
                        type="text"
                        value={
                          address.addressType === "HOME"
                            ? "Domicilio"
                            : address.addressType === "LABORAL"
                            ? "Laboral"
                            : address.addressType
                        }
                        className={styles.input}
                        placeholder="seleccione el tipo"
                        onClick={() => setEditMode(true)}
                        readOnly
                      />
                    )}
                    <Form.Label className={styles.label}>
                      Tipo de dirección
                    </Form.Label>
                  </Form.Floating>
                </Form.Group>
              </Col>
              {address.addressType === "OTHER" && (
                <Col md={4}>
                  <Form.Group as={Col} className={`${styles.flexCol}`}>
                    <Form.Floating className="mt-3">
                      <Form.Control
                        name="other_address_description"
                        className={styles.input}
                        type="text"
                        placeholder="Ingrese la descripción"
                        value={otherDescription}
                        onChange={(e) => setOtherDescription(e.target.value)}
                        required
                      />
                      <label
                        htmlFor="other_address_description"
                        className={styles.label}
                      >
                        Especifique
                      </label>
                    </Form.Floating>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <br />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button size="sm" variant="outline-primary" onClick={handleClose}>
            Cerrar
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={add ? handleSave : patch}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner size="sm" animation="border" role="status" />
                &nbsp;&nbsp;
                <span>{add ? "Agregando..." : "Actualizando..."}</span>
              </>
            ) : (
              <span>{add ? "Agregar Dirección" : "Guardar Cambios"}</span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
};

export default DirectionEdit;
