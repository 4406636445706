import Header from "./components/Header";
import DashboardFiscalAdjunto from "./pages/dashboard/fiscalia-adjunta/DashboardFiscalAdjunto";
import PrivateRoute from "./components/PrivateRoute";
import NotFound from "./pages/NotFound";
import { Route, Routes } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { roles } from "./Keycloak";
import Causas from "./pages/causas/Causas/Causas";
import CausaContext from "./pages/causas/CausaContext/CausaContext";
import Admin from "./pages/admin/Admin";
import "./App.css";
import DashboardFiscal from "./pages/dashboard/fiscalia/DashboardFiscal";
import Alerts from "./pages/alerts";
import PersonaGeneral from "./pages/personas/PersonaGeneral";
import ConsultarPersona from "./pages/personas/ConsultarPersona/ConsultarPersona";
import PersonaContext from "./pages/personas/PersonaContext/PersonaContext";
import Depositos from "./pages/depositos/Depositos";
import ConsultarDeposito from "./pages/depositos/ConsultarDeposito/ConsultarDeposito";
import InstitucionForm from "./pages/institucion/InstitucionForm";
import ConsultarInstituciones from "./pages/institucion/Consultar/ConsultarInstituciones";
import InstitucionContext from "./pages/institucion/Consultar/Context/InstitucionContext";

function App() {
  const { keycloak } = useKeycloak();
  return (
    <Routes>
      <Route
        path="/causas/:id"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <CausaContext />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/causas"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <Causas />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/alertas"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <Alerts />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <PrivateRoute allowedRoles={["admin"]}>
            <div className="App">
              <Header />
              <div className="container">
                <Admin />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">{dashboard(keycloak)}</div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/crear_persona"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <PersonaGeneral />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/personas"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <ConsultarPersona />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/personas/:id"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <PersonaContext />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/depositos/registrar"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <Depositos />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/depositos"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <ConsultarDeposito />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/institucion"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <ConsultarInstituciones />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/institucion/:id"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <InstitucionContext />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route
        path="/institucion/crear"
        element={
          <PrivateRoute allowedRoles={["fiscal", "asistente-fiscal"]}>
            <div className="App">
              <Header />
              <div className="container">
                <InstitucionForm />
              </div>
            </div>
          </PrivateRoute>
        }
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

const dashboard = (keycloak) => {
  if (!roles(keycloak)) {
    return <></>;
  }
  if (roles(keycloak).includes("fiscal-adjunto")) {
    return <DashboardFiscalAdjunto />;
  } else if (
    roles(keycloak).includes("fiscal") ||
    roles(keycloak).includes("asistente-fiscal")
  ) {
    return <DashboardFiscal />;
  } else return <></>;
};

export default App;
